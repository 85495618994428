<template>
  <fusioncharts
    :type="type"
    :width="width"
    :height="height"
    :dataFormat="dataFormat"
    :dataSource="dataSource"
  ></fusioncharts>
  <!-- <fusioncharts
    :type="type"
    :width="width"
    :height="height"
    :dataFormat="dataFormat"
    :dataEmptyMessage="dataEmptyMessage"
    :dataSource="dataSource"
    ref="chartRef"
  ></fusioncharts> -->
</template>

<script>
export default {
  name: "Line2d",
  props: {
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    title: {
      type: String,
    },
    data: {
      type: Array,
    },
  },
  data: function() {
    return {
      type: "msline",
      dataFormat: "json",
      loadMessage: "loading...",
      loadMessageColor: "#000000",
      dataEmptyMessage: "No data to display",
      dataSource: {
        chart: {
          caption: "",
          yaxisname: "Gain-Drain mAh",
          sYaxisname: "Battery",
          subcaption: "",
          showhovereffect: "1",
          numbersuffix: "",
          drawcrossline: "1",
          plottooltext: "<b>$dataValue</b> mA $seriesName",
          theme: "fusion",
        },
        categories: [
          {
            category: [
              {
                label: "14:10",
              },
              {
                label: "14:20",
              },
              {
                label: "14:30",
              },
              {
                label: "14:40",
              },
              {
                label: "14:50",
              },
            ],
          },
        ],
        dataset: [
          {
            seriesname: "Gain",
            color: "#337700",
            data: [
              {
                value: "20",
              },
              {
                value: "40",
              },
              {
                value: "45",
              },
              {
                value: "35",
              },
              {
                value: "70",
              },
            ],
          },
          {
            seriesname: "Drain",
            color: "#ff2703",
            data: [
              {
                value: "18",
              },
              {
                value: "19",
              },
              {
                value: "21",
              },
              {
                value: "21",
              },
              {
                value: "24",
              },
            ],
          },
          {
            seriesname: "Battery",
            color: "#ccaa00",
            data: [
              {
                value: "20",
              },
              {
                value: "30",
              },
              {
                value: "35",
              },
              {
                value: "48",
              },
              {
                value: "80",
              },
            ],
          },
        ],
      },
    };
  },
  mounted: function() {},

  methods: {
  },
};
</script>

<style></style>
