<template>
  <div class="main-container">
    <v-row class="alert-container">
      <v-alert v-if="showMessage" class="alert" :type="messageType" dark>
        {{ message }}
      </v-alert>
    </v-row>
    <v-form ref="form" v-model="valid" lazy-validation>
      <div class="box-container">
        <h2 class="heading">Sign In</h2>
        <div class="form-fields">
          <v-text-field v-model="user.email" :rules="emailRules" placeholder="Email" required solo dense></v-text-field>
        </div>
        <div class="form-fields">
          <v-text-field
            v-model="user.password"
            :rules="passwordRules"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
            placeholder="Password"
            required
            solo
            dense
          ></v-text-field>
        </div>
        <div class="form-fields">
          <v-btn class="button" :disabled="!valid" color="primary" @click="handleLogin">
            Signin
          </v-btn>
        </div>
        <!-- <div class="login-choice"><span>or</span></div>
        <SocialLogin /> -->
      </div>
    </v-form>
    <div class="footer">
      <p>Don't have an account? <router-link to="/register" style="color:#00f"> Create one now</router-link></p>
    </div>
  </div>
</template>

<script>
import User from "../models/user";
// import SocialLogin from "@/components/SocialLogin";
export default {
  name: "login",
  components: {
    // SocialLogin,
  },
  data() {
    return {
      message: null,
      messageType: null,
      showMessage: false,
      loading: false,
      user: new User("", ""),
      show1: false,
      valid: false,
      usernameRules: [
        (v) => !!v || "Username is required",
        (v) => (v && v.length <= 15) || "Name must be less than 15 characters",
      ],
      passwordRules: [(v) => !!v || "Password is required"],
      email: "",
      emailRules: [
        // (value) => !!value || "Email is required.",
        // (value) => (value || "").length <= 50 || "Max 50 characters",
        // (value) =>
        //   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        //     value
        //   ) || "Invalid e-mail.",
      ],
    };
  },
  computed: {
    // loggedIn() {
    //   return this.$store.state.loggedIn;
    // }
    accessToken() {
      return this.$store.state.accessToken;
    },
  },
  watch: {
    user() {
      if (this.validate()) {
        this.valid = true;
      } else {
        this.valid = false;
      }
      // if(newValue.username != null && newValue.username != ''
      //   && newValue.password != null && newValue.password != '') {
      //     this.valid = true;
      //   }
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    handleLogin() {
      this.showMessage = false;
      this.message = "";
      this.loading = true;
      if (this.user.email && this.user.password) {
        this.$store.dispatch("auth/login", this.user).then(
          () => {
            this.$router.push("/");
          },
          (error) => {
            this.loading = false;
            this.message = (error.response && error.response.data) || error.message || error.toString();
            this.showMessage = true;
            this.messageType = "error";
            window.setTimeout(() => {
              this.showMessage = false;
            }, 5000);
          }
        );
      }
    },
  },
};
</script>

<style scoped>
.main-container {
  margin-top: 3%;
  width: 100%;
}
.alert-container {
  width: 100%;
  position: relative;
}
.alert {
  width: 100%;
}
.box-container {
  padding: 20px;
  margin: 0 auto;
  width: 25rem;
  box-shadow: beige;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
}
.heading {
  text-align: center;
  font-weight: 300;
  color: #444;
  margin: 0 auto 45px;
  font-size: 35px;
  line-height: 38px;
  text-transform: none;
  letter-spacing: 0;
}
.form-fields,
.form-fields button {
  width: 100%;
  margin: 5px 0;
  line-height: 28px;
  border-radius: 5px;
}
/* .form-fields input {
  width: 100%;
  line-height: 40px;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  background: #fff;
  padding: 0 5px;
  font-size: 14px;
} */
.signIn {
  padding: 10px 32px;
  color: white;
  font-size: 16px;
  font-weight: 400;
  background: #15cd72;
  text-align: center;
  cursor: pointer;
  height: auto;
  -webkit-appearance: none;
}
.login-choice span {
  color: #5b6987;
  display: -ms-grid;
  display: grid;
  font-size: 16px;
  width: 100%;
  line-height: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -ms-grid-columns: minmax(20px, 1fr) auto minmax(20px, 1fr);
  grid-template-columns: minmax(20px, 1fr) auto minmax(20px, 1fr);
  grid-gap: 19px;
}
.login-choice span:after,
.login-choice span:before {
  content: "";
  border-top: 1px solid #e5e8ed;
}
.footer,
.footer a {
  text-align: center;
  color: #000;
}
</style>
