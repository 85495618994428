<template>
  <v-container>
    <v-row>
      <v-col
        class="d-flex top-object"
        cols="3"
      >
        <v-select
          v-model="loraDeviceSelected"
          :items="loraDeviceNames"
          label="Devices"
          chips
          small-chips
          outlined
          >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="d-flex top-object"
        cols="2"
      >
        <v-btn v-if="!streaming"
         :disabled="loraDeviceSelected == null || loraDeviceSelected == ''"
         color="primary"
         @click="startStreaming"
        >
          Start Streaming
        </v-btn>
        <v-btn v-else
         color="primary"
         @click="stopStreaming"
        >
          Stop Streaming
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col id="chart" cols="12">
        <apexchart
         @animationEnd="animationEndHandler"
         type="line"
         height="350"
         :options="chartOptions"
         :series="series"
        >
        </apexchart>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import ZonedDateTime from "zoned-date-time";

export default {
  components: {
  },
  data: () => ({
    streamingIntervalHandler: null,
    streaming: false,
    loraDeviceMap: null,
    loraDeviceNames: [],
    loraDeviceSelected: '',
    date: null,
    lastDate: null,
    gainData: [],
    luxData: [],
    voltageData: [],
    temperatureData: [],
    dateData: [],
    series: [
        {
          name: 'Gain',
          type: 'line',
          color: '#337700',
          data: [],
        },
        {
          name: 'Lux',
          type: 'line',
          color: '#ffff00',
          data: [],
        },
        {
          name: 'Voltage',
          type: 'line',
          color: '#ccaa00',
          data: [],
        },
        {
          name: 'Temperature',
          type: 'line',
          color: '#132277',
          data: [],
        },
    ],
    chartOptions: {
      chart: {
        height: 350,
        background: '#eee',
        animations: {
          enabled: true,
          easing: 'linear',
          dynamicAnimation: {
            speed: 1000
          },
        },
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        dropShadow: {
          enabled: true,
          opacity: 0.3,
          blur: 5,
          left: -7,
          top: 22
        },
      },
      noData: {
        text: 'No data to show',
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [3, 3, 3, 3],
        curve: 'smooth'
      },
      title: {
        text: 'Live Metrics',
        align: 'center'
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: 'bottom',
        horizontalAlign: 'center',
        floating: false,
        fontSize: '14px',
        fontFamily: 'Helvetica, Arial',
        fontWeight: 400,
        formatter: undefined,
        inverseOrder: false,
        width: undefined,
        height: undefined,
        tooltipHoverFormatter: undefined,
        offsetX: 0,
        offsetY: 0,
        labels: {
            colors: undefined,
            useSeriesColors: false,
        },
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: ['#337700', '#ffff00', '#ccaa00', '#132277'],
          colors: ['#337700', '#ffff00', '#ccaa00', '#132277'],
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0
        },
        itemMargin: {
          horizontal: 5,
          vertical: 5
        },
        onItemClick: {
          toggleDataSeries: true
        },
        onItemHover: {
          highlightDataSeries: true
        },
      },
      xaxis: {
        type: 'time',
        range: 10,
      },
      yaxis: [
        { // Gain
          show: true,
          seriesName: 'Gain',
          axisBorder: {
              show: true,
              color: '#337700',
              offsetX: -5,
              offsetY: 0
          },
          axisTicks: {
              show: true,
              borderType: 'solid',
              color: '#337700',
              width: 6,
              offsetX: 0,
              offsetY: 0
          },
          labels: {
            formatter: function(value) { return value.toFixed(2) + ' mA'; }
          },
          title: {
            text: 'Gain',
          },
        },
        { // Lux
          axisBorder: {
              show: true,
              color: '#ffff00',
              offsetX: 0,
              offsetY: 0
          },
          axisTicks: {
              show: true,
              borderType: 'solid',
              color: '#ffff00',
              width: 6,
              offsetX: 0,
              offsetY: 0
          },
          labels: {
            formatter: function(value) { return (value !== null ? value.toFixed(0) + ' Lux' : value); }
          },
          title: {
            text: 'Lux',
          },
        },
        { // Voltage
          opposite: true,
          min: 3.2,
          max: 4.2,
          axisBorder: {
              show: true,
              color: '#ccaa00',
              offsetX: 0,
              offsetY: 0
          },
          axisTicks: {
              show: true,
              borderType: 'solid',
              color: '#ccaa00',
              width: 6,
              offsetX: 0,
              offsetY: 0
          },
          labels: {
            formatter: function(value) { return (value !== null ? value.toFixed(2) + ' V' : value); }
          },
          title: {
            text: 'Voltage',
            rotate: 90,
          },
        },
        { // Temperature
          opposite: true,
          axisBorder: {
              show: true,
              color: '#132277',
              offsetX: 0,
              offsetY: 0
          },
          axisTicks: {
              show: true,
              borderType: 'solid',
              color: '#132277',
              width: 6,
              offsetX: 0,
              offsetY: 0
          },
          labels: {
            formatter: function(value) { return (value !== null ? value.toFixed(1) + '℃' : value); }
          },
          title: {
            text: 'Temperature',
            rotate: 90,
          },
        },
      ],
      colors: ['#337700', '#ffff00', '#ccaa00', '#132277'],
      fill: {
        type: ['solid', 'solid', 'solid', 'solid'],
        pattern: {
                style: ['','','',''],
                width: 5,
                height: 6
              },
        colors: ['', '', '', ''],
      },
    },
  }),

  computed: {
    accessToken() {
      return JSON.parse(localStorage.getItem('user')).accessToken;
    },
  },

  watch: {
  },

  created() {
    this.getDevices();
  },

  mounted() {
    this.initSeries();
  },

  beforeRouteLeave: function(to, from, next) {
    this.stopStreaming();
    next();
  },

  methods: {
    markerClick() {
      this.clearGraph();
    },
    clearGraph() {
      this.gainData = [];
      this.luxData = [];
      this.voltageData = [];
      this.temperatureData = [];
      this.dateData = [];
      for(let i = 0; i < this.series.length; i += 1) {
        this.series[i].data = [];
      }
    },
    getDevices() {
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      h.append("Content-Type", "application/json");
      let options = {
          method: 'GET',
          headers: h,
          redirect: 'follow'};

      this.loraDeviceNames = [];
      fetch(this.$globals.API_URL + `/device?type=lora`, options)
        .then(async (response) => {
          if( response.status == 401 ){
            this.$store.dispatch('auth/logout')
            .then(() => {
                this.$router.push('/login');
              }, error => {
                this.loading = false;
                this.message =
                  (error.response && error.response.data) ||
                  error.message ||
                  error.toString();
              }
            );
          }
          const data = await response.json();
          this.loraDeviceMap = new Map();
          for( let i = 0 ; i < data.length; i += 1) {
            this.loraDeviceMap.set(String(data[i].lora_dev_name), String(data[i].device_id));
            this.loraDeviceNames.push(String(data[i].lora_dev_name));
          }
        })
        .catch(e => {
          console.log(`Error : ${e}`);
          this.$router.push('login');
        });
    },
    startStreaming() {
      this.streamData();
      this.streamingIntervalHandler = setInterval(this.streamData, 5 * 1000);
      this.streaming = true;
    },
    stopStreaming() {
      clearInterval(this.streamingIntervalHandler);
      this.streaming = false;
    },
    streamData() {
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      return new Promise((resolve) => {

        let reqEndpoint = this.$globals.API_URL + `/device-events/${this.loraDeviceMap.get(this.loraDeviceSelected)}`;

        fetch(reqEndpoint, {
          headers: h,
        })
          .then(async (response) => {
            if( response.status == 401 ){
              this.$store.dispatch('auth/logout')
              .then(() => {
                  this.$router.push('/login');
                }, error => {
                  this.loading = false;
                  this.message =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                }
              );
            }
            const data = await response.json();
            for (let i = 0; i < data.length; i += 1) {
              if(this.lastDate === data[i].timestamp_utc) {
                break;
              }
              this.gainData.push(data[i].gain);
              this.luxData.push(data[i].lux);
              this.voltageData.push(data[i].source_voltage);
              this.temperatureData.push(data[i].temperature);
              console.log(data[i].timestamp_utc);
              const date = new Date(data[i].timestamp_utc).toLocaleTimeString([], { hour12: false });
              // const date = new ZonedDateTime(data[i].timestamp_utc);
              console.log(date);

              this.dateData.push(date);
              this.lastDate = data[i].timestamp_utc;
            }
            this.updateGain();
            this.updateLux();
            this.updateVoltage();
            this.updateTemperature();
            // this.updateGraphDate();
            resolve(data);
          });
      });
    },
    initSeries() {
      for(let i = 0; i < this.series.length; i += 1) {
        this.series[i].data = [];
      }
    },
    updateGain() {
      this.series[0].data = this.gainData;
      this.chartOptions = {
        labels: this.dateData,
      };
    },
    updateLux() {
      this.series[1].data = this.luxData;
      this.chartOptions = {
        labels: this.dateData,
      };
    },
    updateVoltage() {
      this.series[2].data = this.voltageData;
      this.chartOptions = {
        labels: this.dateData,
      };
    },
    updateTemperature() {
      this.series[3].data = this.temperatureData;
      this.chartOptions = {
        labels: this.dateData,
      };
    },
    animationEndHandler() {
        // const newData = this.gainData.slice();
        // newData.shift();
        // window.setTimeout(function () {
        //   chartCtx.updateOptions(
        //     {
        //       series: [
        //         {
        //           data: newData
        //         }
        //       ],
        //       xaxis: {
        //         min: chartCtx.minX,
        //         max: chartCtx.maxX
        //       },
        //       subtitle: {
        //         text:
        //           parseInt(getRangeRandom({ min: 1, max: 20 })).toString() + "%"
        //       }
        //     },
        //     false,
        //     false
        //   );
        // }, 300);
      },
    },

    // updateGraphDate() {
    //   this.chartOptions = {
    //     labels: this.dateData,
    //   };
    // },
};

</script>

<style scoped>
.top-object {
  margin-top: 30px;
}
.class-absolute {
  height: 350px;
  width: 100%;
  position: absolute;
}
</style>