<template>
  <div class="main-container">
    <v-form v-model="valid">
      <div class="box-container">
        <h2 class="heading">
          Create Your Account
        </h2>
        <div class="form-fields">
          <v-text-field v-model="user.firstname" :rules="rules.nameRules" label="First name" outlined dense></v-text-field>
        </div>
        <div class="form-fields">
          <v-text-field v-model="user.lastname" :rules="rules.nameRules" label="Last name" outlined dense></v-text-field>
        </div>
        <div class="form-fields">
          <v-text-field v-model="user.email" :rules="rules.emailRules" label="Email" outlined dense></v-text-field>
        </div>
        <div class="form-fields">
          <v-text-field
            v-model="user.password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="rules.passwordRules"
            :type="show1 ? 'text' : 'password'"
            label="Password"
            outlined
            hint="At least 8 characters"
            @click:append="show1 = !show1"
          ></v-text-field>
        </div>
        <div class="form-fields">
          <v-text-field
            v-model="retypepassword"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="rules.verifyPasswordRules"
            :type="show2 ? 'text' : 'password'"
            label="Retype Password"
            outlined
            @click:append="show2 = !show2"
          ></v-text-field>
        </div>
        <div class="form-fields">
          <v-btn class="create-account" :disabled="!valid" color="success" @click="registerUser">
            Create your account
          </v-btn>
        </div>
        <!-- <div class="login-choice"><span>or</span></div>
        <SocialSignUp /> -->
        <!-- <div>
                    <p class="center">
                        By signing up you agree to the
                        <a href="#">Terms of Service</a>.
                    </p>
                </div> -->
      </div>
    </v-form>
    <div class="footer">
      <p>Already have an account? <router-link to="/login" style="color:#00f"> Sign In</router-link></p>
    </div>
  </div>
</template>

<script>
import User from "../models/user";
// import SocialSignUp from "../components/SocialSignup";
export default {
  name: "signup",
  components: {
    // SocialSignUp,
  },
  data() {
    return {
      user: new User("", "", "", ""),
      retypepassword: "",
      message: null,
      messageType: null,
      showMessage: false,
      show1: false,
      show2: false,
      valid: false,
      rules: {
        nameRules: [(v) => !!v || "Name is required!", (v) => v.length <= 10 || "Name must be maximum 10 characters!"],
        emailRules: [
          (v) => !!v || "E-mail is required!",
          (v) => /.+@.+\..+[a-zA-Z]{2,}/.test(v) || "E-mail must be valid!",
        ],
        usernameRules: [(v) => !!v || "Username is required!", (value) => value.length >= 8 || "Min 8 characters!"],
        passwordRules: [
          (v) => !!v || "Password is required!",
          (v) => v.length >= 8 || "Password must be min 8 characters!",
        ],
        verifyPasswordRules: [
          (v) => !!v || "You need to retype your password!",
          (v) => v == this.user.password || "Passwords don't match"
        ],
      },
    };
  },
  methods: {
    registerUser() {
      this.$store.dispatch("auth/register", this.user).then(
        (data) => {
          console.log(data);
          this.showMessage = true;
          this.message = data.message;
          if (data.success) {
            this.messageType = "success";
          } else {
            this.messageType = "error";
          }
          window.setTimeout(() => {
            this.showMessage = false;
            if (data.success) {
              this.$router.push("/login");
            }
          }, 3000);
        },
        (error) => {
          console.log(JSON.stringify(error));
          this.loading = false;
          this.message = error.response.data.message;
          (error.response && error.response.data.message) || error.message || error.toString();
          this.showMessage = true;
        }
      );
    },
  },
};
</script>

<style scoped>
.main-container {
  margin-top: 3%;
}
.box-container {
  padding: 20px;
  margin: 0 auto;
  width: 400px;
  box-shadow: beige;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
}
.heading {
  text-align: center;
  font-weight: 300;
  color: #444;
  margin: 0 auto 45px;
  font-size: 35px;
  line-height: 38px;
  text-transform: none;
  letter-spacing: 0;
}
.form-fields,
.form-fields button {
  width: 100%;
  margin: 5px 0;
  line-height: 28px;
  border-radius: 5px;
}
.form-fields input {
  width: 100%;
  line-height: 40px;
  border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  background: #fff;
  padding: 0 5px;
  font-size: 14px;
}
.create-account {
  padding: 15px;
  background-color: #0069ff;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  height: 48px;
  line-height: 48px;
  padding: 0 32px;
  text-align: center;
  border-radius: 5px;
}
.login-choice span {
  color: #5b6987;
  display: -ms-grid;
  display: grid;
  font-size: 16px;
  width: 100%;
  line-height: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -ms-grid-columns: minmax(20px, 1fr) auto minmax(20px, 1fr);
  grid-template-columns: minmax(20px, 1fr) auto minmax(20px, 1fr);
  grid-gap: 19px;
}
.login-choice span:after,
.login-choice span:before {
  content: "";
  border-top: 1px solid #e5e8ed;
}
.footer,
.footer a {
  text-align: center;
  color: #000;
}
</style>
