<template>
  <v-container>
    <AdminDashboard v-if="isAdmin" />
    <PowerUserDashboard v-else-if="isPowerUser" />
    <UserDashboard v-else />
  </v-container>
</template>

<script>
import AdminDashboard from "@/components/dashboards/AdminDashboard.vue";
import PowerUserDashboard from "@/components/dashboards/PowerUserDashboard.vue";
import UserDashboard from "@/components/dashboards/UserDashboard.vue";

export default {
  name: "Home",
  components: {
    AdminDashboard,
    PowerUserDashboard,
    UserDashboard,
  },
  data: () => ({}),

  created() {
  },

  computed: {
    accessToken() {
      return JSON.parse(localStorage.getItem("user")).accessToken;
    },
    isAdmin() {
      console.log("isAdmin: ", this.$store.state.auth.user.roles.includes("ROLE_ADMIN"));
      return this.$store.state.auth.user.roles.includes("ROLE_ADMIN");
    },
    isPowerUser() {
      console.log("isPowerUser: ", this.$store.state.auth.user.roles.includes("ROLE_POWER_USER"));
      return this.$store.state.auth.user.roles.includes("ROLE_POWER_USER");
    },
    isUser() {
      console.log("isUser: ", this.$store.state.auth.user.roles.includes("ROLE_USER"));
      return this.$store.state.auth.user.roles.includes("ROLE_USER");
    },
  },

  methods: {
  },
};
</script>

<style></style>
