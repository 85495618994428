<template>
  <v-container>
    <div>
      <v-expansion-panels popout :value="expandFilter">
        <v-expansion-panel class="filter-container">
          <v-expansion-panel-header>
            <h2 class="title" v-if="isCustomer">{{ customer }}</h2>
            Filters
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col v-if="!isCustomer" cols="2">
                <v-autocomplete
                  v-model="customer"
                  :items="filteredCustomersNames"
                  :label="customerFieldLabel"
                  chips
                  dense
                  small-chips
                  single-line
                  @change="onCustomerChange"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-autocomplete
                  v-model="deviceType"
                  :items="filteredDeviceTypes"
                  label="Device Types"
                  chips
                  small-chips
                  dense
                  single-line
                  @change="onDeviceTypeChange"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-autocomplete
                  v-model="testcaseName"
                  :items="filteredTestcasesNames"
                  label="Test-cases"
                  chips
                  small-chips
                  dense
                  single-line
                  @change="onTestcaseChange"
                >
                </v-autocomplete>
              </v-col>
              <!-- <v-col cols="auto">
                <v-autocomplete
                  v-model="modelName"
                  :items="modelNames"
                  label="Models"
                  chips
                  small-chips
                  outlined
                  @change="filterDevices"
                >
                </v-autocomplete>
              </v-col> -->
              <v-col cols="2" v-if="testcaseName != null">
                <v-select
                  v-model="session"
                  :items="sessions"
                  chips
                  small-chips
                  dense
                  single-line
                  return-object
                  @change="onSelectSession"
                >
                  <!-- <template v-slot:selection="{ item }">
                    <v-chip small>
                      <span>{{ item }}</span>
                    </v-chip>
                  </template> -->
                </v-select>
              </v-col>
              <v-col cols="2">
                <v-autocomplete
                  v-model="selectedDeviceId"
                  :items="filteredDevicesIds"
                  label="Devices"
                  chips
                  small-chips
                  dense
                  single-line
                  @change="onDeviceChange"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="2" v-if="testcaseName == null">
                <v-menu
                  v-model="menuFromDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  dense
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on">
                      <v-icon size="24">
                        mdi-calendar
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-date-picker v-model="fromDate" @input="menuFromDate = false"></v-date-picker>
                </v-menu>
                <v-menu
                  ref="menuFromTime"
                  v-model="menuFromTime"
                  :close-on-content-click="false"
                  :return-value.sync="fromTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  left
                  dense
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on">
                      <v-icon size="24">
                        mdi-clock-time-four-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-time-picker
                    v-model="fromTime"
                    full-width
                    format="24h"
                    @click:minute="$refs.menuFromTime.save(fromTime)"
                    dense
                  ></v-time-picker>
                </v-menu>
                <v-text-field v-model="fromDateTime" label="From Date Time" readonly></v-text-field>
              </v-col>

              <v-col cols="2" v-if="testcaseName == null">
                <v-menu
                  v-model="menuToDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  left
                  dense
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on">
                      <v-icon size="24">
                        mdi-calendar
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-date-picker v-model="toDate" @input="menuToDate = false"></v-date-picker>
                </v-menu>
                <v-menu
                  ref="menuToTime"
                  v-model="menuToTime"
                  :close-on-content-click="false"
                  :return-value.sync="toTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  left
                  dense
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on">
                      <v-icon size="24">
                        mdi-clock-time-four-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-time-picker
                    v-if="menuToTime"
                    v-model="toTime"
                    full-width
                    format="24h"
                    @click:minute="$refs.menuToTime.save(toTime)"
                    dense
                  ></v-time-picker>
                </v-menu>
                <v-text-field v-model="toDateTime" label="To Date Time" readonly></v-text-field>
              </v-col>

              <!-- <v-col cols="2" v-if="testcaseName == null">
                  <v-menu
                    v-model="menuFromDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    dense
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fromDate"
                        label="From Date"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        dense
                        single-line
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="fromDate" @input="menuFromDate = false"></v-date-picker>
                  </v-menu>
                </v-col> -->

              <!-- <v-menu
                  ref="menuTime"
                  v-model="menuFromTime"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="fromTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  dense
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fromTime"
                      label="Picker in menu"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menuFromTime"
                    v-model="fromTime"
                    full-width
                    format="24h"
                    @click:minute="$refs.menuTime.save(fromTime)"
                    dense
                  ></v-time-picker>
                </v-menu> -->

              <!-- <v-col cols="2">
                <v-menu
                  v-if="testcaseName == null"
                  v-model="menuToDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  dense
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="toDate"
                      label="To Date"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      single-line
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="toDate" @input="menuToDate = false"></v-date-picker>
                </v-menu>
              </v-col> -->
            </v-row>
            <v-btn :disabled="!filters" color="primary" @click="fetchDeviceData">
              Get Data
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <v-row>
      <v-container class="total-graph-container">
        <v-overlay
          v-if="fetchingData"
          class="d-flex justify-center total-graph-absolute"
          :value="fetchingData"
          :opacity="0.3"
        >
          <v-progress-circular :size="50" :width="5" color="lightgrey" indeterminate></v-progress-circular>
        </v-overlay>
        <ms-column2d
          v-bind:width="totalGraphWidth"
          v-bind:height="totalGraphHeight"
          v-bind:caption="totalGraphCaption"
          v-bind:categories="totalGraphCategories"
          v-bind:dataset="totalGraphDataset"
        />
      </v-container>
      <!-- <div>
        <v-overlay
          v-if="fetchingData"
          class="d-flex justify-center align-center total-graph-absolute"
          :value="fetchingData"
          :opacity="0.3"
        >
          <v-progress-circular :size="50" :width="5" color="lightgrey" indeterminate></v-progress-circular>
        </v-overlay>
        <stacked-column2d
          v-bind:caption="totalGraphCaption"
          v-bind:categories="totalGraphCategories"
          v-bind:dataset="totalGraphHigherDataset"
        />
      </div> -->
    </v-row>
    <v-row>
      <v-container class="time-series-graph-container">
        <v-overlay
          v-if="fetchingData"
          class="d-flex justify-center time-series-graph-absolute"
          :value="fetchingData"
          :opacity="0.3"
        >
          <v-progress-circular :size="100" :width="10" color="lightgrey" indeterminate></v-progress-circular>
        </v-overlay>
        <!-- <time-series-chart :width="timeSeriesWidth" :height="timeSeriesHeight" v-bind:data="timeSeriesData" :dayData="timeSeriesDayData"/> -->
        <time-series-chart :width="timeSeriesWidth" :height="timeSeriesHeight" v-bind:data="timeSeriesData"/>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import MsColumn2d from "../components/charts/MsColumn2d.vue";
import TimeSeriesChart from "../components/charts/TimeSeriesChart.vue";

export default {
  components: {
    TimeSeriesChart,
    MsColumn2d,
  },
  data: () => ({
    expandFilter: 0,
    fetchingData: false,
    noDataText: "",
    filters: false,
    deviceType: null,
    customer: null,
    selectedDeviceId: null,
    testcaseName: null,
    session: null,
    deviceTypes: [],
    customersNames: [],
    devicesIds: [],
    testcases: [],
    sessions: [],
    filteredDeviceTypes: [],
    filteredCustomersNames: [],
    filteredDevicesIds: [],
    filteredTestcases: [],
    filteredTestcasesNames: [],
    // modelName: "",
    // modelNames: [],
    devices: [],
    fromDateTime: null,
    toDateTime: null,
    fromDate: null, //new Date().toDateString(),
    toDate: null, //new Date().toDateString(),
    menuFromDate: false,
    menuToDate: false,
    fromTime: null, //new Date().toTimeString(),
    toTime: null, //new Date().toTimeString(),
    menuFromTime: false,
    menuToTime: false,
    totalGraphWidth: "300",
    totalGraphHeight: "200",
    totalGraphCaption: "Total Gain-Drain",
    totalGraphCategories: [
      {
        category: [
          {
            label: "",
          },
        ],
      },
    ],
    totalGraphDataset: [
      {
        seriesname: "Gain",
        data: [
          {
            value: 0,
            color: "#337700",
          },
        ],
      },
      {
        seriesname: "Drain",
        data: [
          {
            value: 0,
            color: "#ff2703",
          },
        ],
      },
    ],
    totalGraphHigherDataset: [
      {
        seriesname: "Gain",
        data: [
          {
            value: 0,
            color: "#337700",
          },
        ],
      },
      {
        seriesname: "Drain",
        data: [
          {
            value: 0,
            color: "#ff2703",
          },
        ],
      },
    ],
    timeSeriesWidth: "100%",
    timeSeriesHeight: "600",
    timeSeriesData: [],
    timeSeriesDayData: [],
  }),

  computed: {
    customerFieldLabel() {
      return this.$globals.CUSTOMER_FIELD_LABEL;
    },
    isCustomer() {
      console.log("isCustomer: ", JSON.parse(localStorage.getItem("user")).roles);
      return JSON.parse(localStorage.getItem("user")).roles.includes("ROLE_POWER_USER");
    },
    accessToken() {
      return JSON.parse(localStorage.getItem("user")).accessToken;
    },
    getStoreDeviceType() {
      return this.$store.getters.getStoreDeviceType;
    },
    getStoreBrandName() {
      return this.$store.getters.getStoreBrandName;
    },
    getStoreSession() {
      return this.$store.getters.getStoreSession;
    },
    getStoreDeviceId() {
      return this.$store.getters.getStoreDeviceId;
    },
    getStoreStartDate() {
      return this.$store.getters.getStoreStartDate;
    },
    getStoreEndDate() {
      return this.$store.getters.getStoreEndDate;
    },
    testcaseId() {
      for(let i = 0; i < this.testcases.length; i += 1) {
        if (this.testcases[i].name === this.testcaseName) {
          return this.testcases[i].id;
        }
      }
      return null;
    },
  },

  watch: {
    fromDate() {
      // this.clearTotalGraph();
      // this.clearTimeSeries();
      if (
        this.deviceType != null &&
        this.deviceType != "" &&
        this.selectedDeviceId != null &&
        this.selectedDeviceId != "" &&
        (this.getStoreStartDate == null || this.getStoreStartDate == "") &&
        (this.getStoreEndDate == null || this.getStoreEndDate == "") &&
        (((this.fromDate == null || this.fromDate == "") &&
          (this.fromTime == null || this.fromTime == "") &&
          (this.toDate == null || this.toDate == "") &&
          (this.toTime == null || this.toTime == "")) ||
          (this.fromDate != null &&
            this.fromDate != "" &&
            this.fromTime != null &&
            this.fromTime != "" &&
            this.toDate != null &&
            this.toDate != "" &&
            this.toTime != null &&
            this.toTime != ""))
      ) {
        this.filters = true;
      }
      this.fromDateTime = this.fromDate + ", " + (this.fromTime != null ? this.fromTime : "");
    },
    fromTime() {
      if (
        ((this.fromDate == null || this.fromDate == "") &&
          (this.fromTime == null || this.fromTime == "") &&
          (this.toDate == null || this.toDate == "") &&
          (this.toTime == null || this.toTime == "")) ||
        (this.fromDate != null &&
          this.fromDate != "" &&
          this.fromTime != null &&
          this.fromTime != "" &&
          this.toDate != null &&
          this.toDate != "" &&
          this.toTime != null &&
          this.toTime != "")
      ) {
        this.filters = true;
      }
      this.fromDateTime = (this.fromDate != null ? this.fromDate : "") + ", " + this.fromTime;
    },
    toDate() {
      // this.clearTotalGraph();
      // this.clearTimeSeries();
      if (
        this.deviceType != null &&
        this.selectedDeviceId != null &&
        (this.getStoreStartDate == null || this.getStoreStartDate == "") &&
        (this.getStoreEndDate == null || this.getStoreEndDate == "") &&
        (((this.fromDate == null || this.fromDate == "") &&
          (this.fromTime == null || this.fromTime == "") &&
          (this.toDate == null || this.toDate == "") &&
          (this.toTime == null || this.toTime == "")) ||
          (this.fromDate != null &&
            this.fromDate != "" &&
            this.fromTime != null &&
            this.fromTime != "" &&
            this.toDate != null &&
            this.toDate != "" &&
            this.toTime != null &&
            this.toTime != ""))
      ) {
        this.filters = true;
      }
      this.toDateTime = this.toDate + ", " + (this.toTime != null ? this.toTime : "");
    },
    toTime() {
      if (
        ((this.fromDate == null || this.fromDate == "") &&
          (this.fromTime == null || this.fromTime == "") &&
          (this.toDate == null || this.toDate == "") &&
          (this.toTime == null || this.toTime == "")) ||
        (this.fromDate != null &&
          this.fromDate != "" &&
          this.fromTime != null &&
          this.fromTime != "" &&
          this.toDate != null &&
          this.toDate != "" &&
          this.toTime != null &&
          this.toTime != "")
      ) {
        this.filters = true;
      }
      this.toDateTime = (this.toDate != null ? this.toDate : "") + ", " + this.toTime;
    },
    deviceType() {
      // this.clearTotalGraph();
      // this.clearTimeSeries();
      if (
        this.selectedDeviceId != null &&
        (this.getStoreStartDate == null || this.getStoreStartDate == "") &&
        (this.getStoreEndDate == null || this.getStoreEndDate == "") &&
        (((this.fromDate == null || this.fromDate == "") &&
          (this.fromTime == null || this.fromTime == "") &&
          (this.toDate == null || this.toDate == "") &&
          (this.toTime == null || this.toTime == "")) ||
          (this.fromDate != null &&
            this.fromDate != "" &&
            this.fromTime != null &&
            this.fromTime != "" &&
            this.toDate != null &&
            this.toDate != "" &&
            this.toTime != null &&
            this.toTime != ""))
      ) {
        this.filters = true;
      }
    },
    selectedDeviceId() {
      if (
        this.selectedDeviceId != null &&
        (((this.fromDate == null || this.fromDate == "") &&
          (this.fromTime == null || this.fromTime == "") &&
          (this.toDate == null || this.toDate == "") &&
          (this.toTime == null || this.toTime == "")) ||
          (this.fromDate != null &&
            this.fromDate != "" &&
            this.fromTime != null &&
            this.fromTime != "" &&
            this.toDate != null &&
            this.toDate != "" &&
            this.toTime != null &&
            this.toTime != ""))
      ) {
        this.filters = true;
        // this.clearTotalGraph();
        // this.clearTimeSeries();
      } else {
        this.filters = false;
      }

      if (
        (this.getStoreStartDate == null || this.getStoreStartDate == "") &&
        (this.getStoreEndDate == null || this.getStoreEndDate == "") &&
        (((this.fromDate == null || this.fromDate == "") &&
          (this.fromTime == null || this.fromTime == "") &&
          (this.toDate == null || this.toDate == "") &&
          (this.toTime == null || this.toTime == "")) ||
          (this.fromDate != null &&
            this.fromDate != "" &&
            this.fromTime != null &&
            this.fromTime != "" &&
            this.toDate != null &&
            this.toDate != "" &&
            this.toTime != null &&
            this.toTime != ""))
      ) {
        this.filters = true;
        // this.clearTotalGraph();
        // this.clearTimeSeries();
      }
    },
    testcaseName() {
      if (this.testcaseName != null) {
        this.filters = true;
      }
    },
    devicesIds() {
      this.filteredDevicesIds = this.devicesIds;
      if (this.filteredDevicesIds.length == 1) {
        this.selectedDeviceId = this.filteredDevicesIds[0];
      }
    },
    sessions() {
      if (this.sessions.length == 1) {
        this.session = this.sessions[0];
      }
    },
  },

  created() {
    // let dt = new Date();
    // this.fromDateTime = dt.getFullYear() + '-' + dt.getMonth() + '-' + dt.getDate() + ', '
    //                   + dt.getHours() + ':' + dt.getMinutes() + ':' + dt.getSeconds();
    console.log(this.getStoreStartDate);
    console.log(this.getStoreEndDate);
    console.log(this.getStoreDeviceType);
    console.log(this.getStoreBrandName);
    console.log(this.getStoreSession);
    console.log(this.getStoreDeviceId);
    if (
      this.getStoreStartDate != null &&
      this.getStoreStartDate != "" &&
      this.getStoreEndDate != null &&
      this.getStoreEndDate != "" &&
      this.getStoreDeviceId != null &&
      this.getStoreDeviceId != ""
    ) {
      this.fromDate = this.getStoreStartDate;
      this.toDate = this.getStoreEndDate;
      this.customer = this.getStoreBrandName;
      this.session = this.getStoreSession;
      this.deviceType = this.getStoreDeviceType;
      this.deviceId = this.getStoreDeviceId;
      this.selectedDeviceId = this.getStoreDeviceId;
      this.fetchDeviceData();
    } else {
      this.fetchDeviceTypes();
      this.fetchTestcases();
      this.fetchDevices();
      if (!this.isCustomer) {
        this.fetchCustomers();
      } else {
        this.fetchCustomerName();
      }
    }
  },

  mounted() {
    // const now = new Date().getTime();
    // const sevenDaysInMillisec = 7 * 24 * 3600 * 1000;
    // this.fromDate = new Date(now - sevenDaysInMillisec);
    // this.fromTime = new Date(now - sevenDaysInMillisec);
    // this.toDate = new Date(now);
    // this.toTime = new Date(now);
    // this.clearTotalGraph();
    // this.clearTimeSeries();
  },

  beforeRouteLeave: function(to, from, next) {
    this.clearStoreData();
    next();
  },

  methods: {
    clearStoreData() {
      this.$store.commit("setStoreDeviceType", "");
      this.$store.commit("setStoreBrandName", "");
      this.$store.commit("setStoreSession", "");
      this.$store.commit("setStoreDeviceId", "");
      this.$store.commit("setStoreStartDate", "");
      this.$store.commit("setStoreEndDate", "");
    },

    async fetchTestcases() {
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      reqEndpoint = this.$globals.API_URL + this.$globals.RES_TESTCASE;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.loading = false;
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      this.testcases = await response.json();

      this.testcases.forEach((testcase) => {
        this.filteredTestcasesNames.push(testcase.name);
      });
      this.filteredTestcases = this.testcases;
    },

    async fetchDeviceTypes() {
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      reqEndpoint = this.$globals.API_URL + `/device-types`;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.loading = false;
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      data.forEach((deviceType) => {
        if (deviceType != null && deviceType != "") {
          this.deviceTypes.push(deviceType);
        }
      });
      this.filteredDeviceTypes = this.deviceTypes;
      if (this.getStoreDeviceType != null && this.getStoreDeviceType != "") {
        this.deviceType = this.getStoreDeviceType;
        this.fetchDevices();
      }
    },

    async fetchCustomers() {
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      reqEndpoint = this.$globals.API_URL + this.$globals.RES_CUSTOMER_BRAND;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      data.forEach((customer) => {
        if (customer.name != null && customer.name != "") {
          this.customersNames.push(customer.name);
        }
      });
      this.filteredCustomersNames = this.customersNames;
    },

    async fetchCustomerName() {
      console.log("getting user data");
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      reqEndpoint = this.$globals.API_URL + this.$globals.RES_USER + "/" + JSON.parse(localStorage.getItem("user")).id;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      console.log("user data: ", data);
      this.customer = data.customer_name;
    },

    async fetchDevices() {
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      reqEndpoint = this.$globals.API_URL + `/device`;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.loading = false;
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      // console.log("Devices: ", data);
      data.forEach((device) => {
        if (device.device_id != null) {
          // console.log('customer: ', device.brand);
          this.devices.push(device);
          this.devicesIds.push(device.device_id);
        }
      });
      this.filteredDevicesIds = this.devicesIds;
      // if (this.getStoreModelName != null && this.getStoreModelName != "") {
      //   this.modelName = this.getStoreModelName;
      // }
      if (this.getStoreDeviceId != null && String(this.getStoreDeviceId) != "") {
        this.selectedDeviceId = String(this.getStoreDeviceId);
      }
    },

    onCustomerChange() {
      if (this.customer == null || this.customer == "") {
        this.filteredDeviceTypes = this.deviceTypes;
        this.filteredTestcases = this.testcases;
        this.filteredDevicesIds = this.devicesIds;
      } else {
        let tmpDevicesIds = new Set();
        let tmpDeviceTypes = new Set();
        let tmpTestcases = new Set();
        let tmpTestcasesNames = new Set();
        this.devices.forEach((device) => {
          if (device.brand == this.customer) {
            if (device.type != null && device.type != "") {
              tmpDeviceTypes.add(device.type);
            }
            if (device.device_id != null && device.device_id != "") {
              tmpDevicesIds.add(device.device_id);
            }
            if (device.testcase_id != null && device.testcase_id != "") {
              this.testcases.forEach((testcase) => {
                if (testcase.id === device.testcase_id) {
                  tmpTestcases.add(testcase);
                  tmpTestcasesNames.add(testcase.name);
                }
              });
            }
          }
        });
        this.filteredDeviceTypes = Array.from(tmpDeviceTypes);
        if (this.filteredDeviceTypes.length == 1) this.deviceType = this.filteredDeviceTypes[0];
        this.filteredTestcases = Array.from(tmpTestcases);
        this.filteredTestcasesNames = Array.from(tmpTestcasesNames);
        // if(this.filteredTestcasesIds.length == 1) this.testcaseName = this.filteredTestcasesIds[0];
        this.filteredDevicesIds = Array.from(tmpDevicesIds);
        if (this.filteredDevicesIds.length == 1) this.selectedDeviceId = this.filteredDevicesIds[0];
      }
    },

    onDeviceTypeChange() {
      if (this.deviceType == null || this.deviceType == "") {
        this.filteredCustomersNames = this.customersNames;
        this.filteredDevicesIds = this.devicesIds;
        this.filteredTestcases = this.testcases;
      } else {
        let tmpCustomersNames = new Set();
        let tmpDevicesIds = new Set();
        let tmpTestcases = new Set();
        let tmpTestcasesNames = new Set();
        this.devices.forEach((device) => {
          if (device.type == this.deviceType) {
            if (device.brand != null && device.brand != "") {
              tmpCustomersNames.add(device.brand);
            }
            if (device.testcase_id != null && device.testcase_id != "") {
              this.testcases.forEach((testcase) => {
                if (testcase.id === device.testcase_id) {
                  tmpTestcases.add(testcase);
                  tmpTestcasesNames.add(testcase.name);
                }
              });
            }
          }
        });
        this.filteredCustomersNames = Array.from(tmpCustomersNames);
        if (this.filteredCustomersNames.length == 1) this.customer = this.filteredCustomersNames[0];
        this.filteredTestcases = Array.from(tmpTestcases);
        this.filteredTestcasesNames = Array.from(tmpTestcasesNames);
        // if(this.filteredTestcasesIds.length == 1) this.testcaseName = this.filteredTestcasesIds[0];
        this.filteredDevicesIds = Array.from(tmpDevicesIds);
        if (this.filteredDevicesIds.length == 1) this.selectedDeviceId = this.filteredDevicesIds[0];
      }
    },

    onDeviceChange() {
      if (this.selectedDeviceId == null || this.selectedDeviceId == "") {
        this.filteredCustomersNames = this.customersNames;
        this.filteredDeviceTypes = this.deviceTypes;
        this.filteredTestcases = this.testcases;
      } else {
        let tmpCustomersNames = new Set();
        let tmpDeviceTypes = new Set();
        let tmpTestcases = new Set();
        let tmpTestcasesNames = new Set();
        this.devices.forEach((device) => {
          if (device.device_id == this.selectedDeviceId) {
            if (device.brand != null && device.brand != "") {
              tmpCustomersNames.add(device.brand);
            }
            if (device.type != null && device.type != "") {
              tmpDeviceTypes.add(device.type);
            }
            if (device.testcase_id != null && device.testcase_id != "") {
              this.testcases.forEach((testcase) => {
                console.log('testcase: ', testcase);
                console.log('device.testcase_id:', device.testcase_id);
                if (testcase.id.toString() === device.testcase_id) {
                  tmpTestcases.add(testcase);
                  tmpTestcasesNames.add(testcase.name);
                }
              });
            }
            this.customer = device.brand;
            this.deviceType = device.type;
          }
        });
        this.filteredCustomersNames = Array.from(tmpCustomersNames);
        this.filteredTestcases = Array.from(tmpTestcases);
        this.filteredTestcasesNames = Array.from(tmpTestcasesNames);
        this.filteredDeviceTypes = Array.from(tmpDeviceTypes);

        if(this.session === null || this.session === '') {
          this.fetchLastDataForDevice();
        }
      }
    },

    async onTestcaseChange() {
      if (this.testcaseName == null || this.testcaseName == "") {
        this.filteredCustomersNames = this.customersNames;
        this.filteredDeviceTypes = this.deviceTypes;
        this.filteredDevicesIds = this.devicesIds;
        return;
      } else {
        let tmpCustomersNames = new Set();
        let tmpDeviceTypes = new Set();
        let tmpDevicesIds = new Set();
        // console.log('testcase id: ', this.testcaseId);
        if (!this.testcaseId) {
          return;
        }
        this.devices.forEach((device) => {
          if (device.testcase_id == this.testcaseId) {
            if (device.brand != null && device.brand != "") {
              tmpCustomersNames.add(device.brand);
            }
            if (device.type != null && device.type != "") {
              tmpDeviceTypes.add(device.type);
            }
            if (device.device_id != null && device.device_id != "") {
              tmpDevicesIds.add(device.device_id);
            }
          }
        });
        this.filteredCustomersNames = Array.from(tmpCustomersNames);
        if (this.filteredCustomersNames.length == 1) this.customer = this.filteredCustomersNames[0];
        this.filteredDevicesIds = Array.from(tmpDevicesIds);
        if (this.filteredDevicesIds.length == 1) this.selectedDeviceId = this.filteredDevicesIds[0];
        this.filteredDeviceTypes = Array.from(tmpDeviceTypes);
        if (this.filteredDeviceTypes.length == 1) this.deviceType = this.filteredDeviceTypes[0];
      }

      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      reqEndpoint = this.$globals.API_URL + this.$globals.RES_TESTCASE + `/${this.testcaseId}`;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.loading = false;
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }

      this.sessions = [];
      const data = await response.json();
      console.log("sessions: ", data);
      if (data.length == 0) {
        return [];
      }

      let s = {
        description: null,
        start: null,
        end: null,
        text: null,
      };
      data.sessions.forEach((session) => {
        s.description = session.description;
        // let sDt = new Date(session.startUtc).toISOString();
        // console.log("start: ", sDt);
        s.start = new Date(session.startUtc).toISOString();
        s.end = new Date(session.endUtc).toISOString();
        let startDt = new Date(session.startUtc);
        let endDt = new Date(session.endUtc);
        // console.log("startDt: ", startDt);
        // console.log("endDt: ", endDt);

        // let startYear = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(startDt);
        // let startMonth = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(startDt);
        // let startDay = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(startDt);
        // let startHour = new Intl.DateTimeFormat('en', { hour: 'numeric', hour12: false }).format(startDt);
        // let startMinute = new Intl.DateTimeFormat('en', { minute: '2-digit' }).format(startDt);
        // let startSecond = new Intl.DateTimeFormat('en', { second: '2-digit' }).format(startDt);
        // let endYear = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(endDt);
        // let endMonth = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(endDt);
        // let endDay = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(endDt);
        // let endHour = new Intl.DateTimeFormat('en', { hour: 'numeric', hour12: false }).format(endDt);
        // let endMinute = new Intl.DateTimeFormat('en', { minute: '2-digit' }).format(endDt);
        // let endSecond = new Intl.DateTimeFormat('en', { second: '2-digit' }).format(endDt);
        // console.log(`Start: ${startYear}-${startMonth}-${startDay} ${startHour}:${startMinute}:${('0' + startSecond).slice(-2)} - End: ${endYear}-${endMonth}-${endDay} ${endHour}:${endMinute}:${('0' + endSecond).slice(-2)}`);
        let sessionText =
          "Start: " +
          startDt.getFullYear() +
          "-" +
          String(startDt.getMonth() + 1).padStart(2, '0') +
          "-" +
          String(startDt.getDate()).padStart(2, '0') +
          " " +
          String(startDt.getHours()).padStart(2, '0') +
          ":" +
          String(startDt.getMinutes()).padStart(2, '0') +
          ":" +
          String(startDt.getSeconds()).padStart(2, '0') +
          " - " +
          "End: " +
          endDt.getFullYear() +
          "-" +
          String(endDt.getMonth() + 1).padStart(2, '0') +
          "-" +
          String(endDt.getDate()).padStart(2, '0') +
          " " +
          String(endDt.getHours()).padStart(2, '0') +
          ":" +
          String(endDt.getMinutes()).padStart(2, '0') +
          ":" +
          String(endDt.getSeconds()).padStart(2, '0');
        s.text = sessionText;
        console.log(sessionText);
        this.sessions.push(s);
        s = {
          description: null,
          start: null,
          end: null,
          text: null,
        };
      });
      if (this.sessions.length == 1) {
        this.session = this.sessions[0];
        this.fromDateTime = this.session.start;
        this.toDateTime = this.session.end;
      }

      // this.updateDevicesByTestcaseId();
    },

    onSelectSession(item) {
      console.log("item: ", item);
      this.fromDate = item.start;
      this.toDate = item.end;
    },

    async fetchDeviceData() {
      this.fetchingData = true;
      this.expandFilter = 1;
      try {
        await this.fetchDeviceDataBetweenDates();
      } catch (e) {
        console.log(e);
      }
      this.fetchingData = false;
    },

    clearTotalGraph() {
      this.totalGraphDataset[0].data[0].value = null;
      this.totalGraphDataset[1].data[0].value = null;
      this.totalGraphHigherDataset[0].data[0].value = null;
      this.totalGraphHigherDataset[1].data[0].value = null;
    },
    clearTimeSeries() {
      this.timeSeriesData = [];
      this.timeSeriesDayData = [];
    },

    async fetchLastDataForDevice() {
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      reqEndpoint = this.$globals.API_URL + `/device-events/${this.selectedDeviceId}`;
      console.log("reqEndpoint: ", reqEndpoint);
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.loading = false;
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      // console.log("data: ", data);

      this.toDate = new Date(data[0].timestamp_utc).toLocaleDateString("sv-SE");
      this.toTime = new Date(data[0].timestamp_utc).toLocaleTimeString("sv-SE");
      const sevenDaysInMillisec = 7 * 24 * 3600 * 1000;
      this.fromDate = new Date(data[0].timestamp_utc - sevenDaysInMillisec).toLocaleDateString("sv-SE");
      this.fromTime = new Date(data[0].timestamp_utc - sevenDaysInMillisec).toLocaleTimeString("sv-SE");
      // console.log("toDate: ", this.toDate);
      // console.log("fromDate: ", this.fromDate);
    },

    async fetchDeviceDataBetweenDates() {
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);

      let path = "";
      if (this.selectedDeviceId != null && this.selectedDeviceId != "") {
        path = `/${this.selectedDeviceId}`;
      } else if (this.testcaseName != null && this.testcaseName != "") {
        path = `?testcaseId=${this.testcaseId}`;
      } else {
        if (this.customer != null && this.customer != "") {
          path += path == "" ? `?customer=${this.customer}` : `&customer=${this.customer}`;
        }
        if (this.deviceType != null && this.deviceType != "") {
          path += path == "" ? `?deviceType=${this.deviceType}` : `&deviceType=${this.deviceType}`;
        }
      }
      if(this.session != null && this.session != '') {
        if (this.fromDateTime === null || this.fromDateTime === '') {
          // show error 'There is no start and end dates for the selected session'
          return;
        }
      }
      if (this.fromDateTime != null && this.fromDateTime != "") {
        const fromDateTime = new Date(this.fromDateTime).toISOString();
        const toDateTime = new Date(this.toDateTime).toISOString();
        path += !path.includes("?") ? `?fromDate=${fromDateTime}` : `&fromDate=${fromDateTime}`;
        path += this.toDate == "" ? `?toDate=${fromDateTime}` : `&toDate=${toDateTime}`;
      } else {
        const now = new Date().getTime();
        const sevenDaysInMillisec = 7 * 24 * 3600 * 1000;
        this.fromDate = new Date(now - sevenDaysInMillisec);
        this.toDate = new Date(now);
      }

      let reqEndpoint = "";
      reqEndpoint = this.$globals.API_URL + `/device-events${path}`;
      console.log("reqEndpoint: ", reqEndpoint);
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.loading = false;
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      console.log("datapoint: ", data);
      let timeSeriesData = [];
      // let timeSeriesDayData = [];
      let totalGainValue = 0;
      let totalDrainValue = 0;
      if (data.length == 0) {
        this.clearTotalGraph();
        this.clearTimeSeries();
        return [];
      }

      // let gain = 0;
      // let drain = 0;
      for (let i = 0; i < data.length; i += 1) {
        data[i].gain = data[i].gain.toFixed(3);
        // gain = data[i].gain / 30;
        data[i].drain = data[i].drain.toFixed(3);
        // drain = data[i].drain / 30;
        // console.log('gain: ', gain);
        // console.log('drain: ', drain);
        data[i].source_voltage = data[i].source_voltage.toFixed(3);
        data[i].temperature = data[i].temperature.toFixed(1);
        let date = new Date(data[i].timestamp_utc).toLocaleString("sv-SE");
        date = date.slice(0, date.length - 3);
        // let date = new Date(data[i].timestamp_utc);
        // date = date.getFullYear() +
        //   "-" +
        //   String(date.getMonth() + 1).padStart(2, '0') +
        //   "-" +
        //   String(date.getDate()).padStart(2, '0') +
        //   " " +
        //   String(date.getHours()).padStart(2, '0') +
        //   ":" +
        //   String(date.getMinutes()).padStart(2, '0');
        // console.log("Date to timeseries: " + date + ", gain: " + gain + ", v: " + data[i].source_voltage + ", t: " + data[i].temperature);
        timeSeriesData.push([date, data[i].gain, data[i].drain, data[i].source_voltage, data[i].temperature]);
        // timeSeriesDayData.push([date, data[i].gain, data[i].drain, data[i].source_voltage, data[i].temperature]);
        totalGainValue += parseFloat(data[i].gain);
        totalDrainValue += parseFloat(data[i].drain);
      }
      this.totalGraphDataset[0].data[0].value = totalGainValue;
      this.totalGraphDataset[1].data[0].value = totalDrainValue;
      if (this.totalGraphHigherDataset[0].data[0].value - this.totalGraphHigherDataset[1].data[0].value > 0) {
        this.totalGraphHigherDataset[0].data[0].value =
          this.totalGraphHigherDataset[0].data[0].value - this.totalGraphHigherDataset[1].data[0].value;
        this.totalGraphHigherDataset[1].data[0].value = 0;
      } else {
        this.totalGraphHigherDataset[1].data[0].value =
          this.totalGraphHigherDataset[0].data[0].value - this.totalGraphHigherDataset[1].data[0].value;
        this.totalGraphHigherDataset[0].data[0].value = 0;
      }
      // console.log(this.totalGraphHigherDataset[0].data[0].value);
      // console.log(this.totalGraphHigherDataset[1].data[0].value);
      this.timeSeriesData = timeSeriesData;
      // this.timeSeriesDayData = timeSeriesDayData;
    },
  },
};
</script>

<style scoped>
.filter-container {
  margin-top: -8px;
}
.total-graph-container {
  margin-top: 2px;
  padding-bottom: 0px;
  position: relative;
}
.total-graph-absolute {
  margin: 20px;
  height: 180px;
  width: 280px;
  position: absolute;
  border-radius: 12px;
}
.time-series-graph-container {
  margin-top: 2px;
  padding-top: 0px;
  position: relative;
}
.time-series-graph-absolute {
  margin: 20px;
  margin-top: 10px;
  margin-bottom: 45px;
  position: absolute;
  border-radius: 12px;
}
</style>
