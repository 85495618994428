<template>
  <fusioncharts
    :type="type"
    :width="width"
    :height="height"
    :dataFormat="dataFormat"
    :dataEmptyMessage="dataEmptyMessage"
    :dataSource="dataSource"
    ref="chartRef"
  ></fusioncharts>
</template>

<script>
export default {
  name: "MsColumn2d",
  props: {
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    caption: {
      type: String,
    },
    categories: {
      type: Array,
    },
    dataset: {
      type: Array,
    },
  },
  data: function() {
    return {
      type: "mscolumn2d",
      dataFormat: "json",
      dataEmptyMessage: 'No data to show',
      dataSource: {
        chart: {
          caption: this.caption,
          subCaption: "",
          bgColor: "#f00000",//"#f7f7f7",
          bgAlpha: "0",
          xAxisName: "",
          yAxisName: "",
          showValues: "0",
          theme: "fusion",
          showLegend: false,
        },
        categories: this.categories,
        dataset: this.dataset,
      },
    };
  },
};
</script>

<style>
</style>
