import Vue from 'vue'
import App from './App.vue'

import FusionCharts from 'fusioncharts';
import VueFusionCharts from "vue-fusioncharts";

import Maps from "fusioncharts/fusioncharts.maps";
import World from "fusioncharts/maps/fusioncharts.world";
import Europe from "fusioncharts/maps/fusioncharts.europe";
import Greece from 'fusioncharts/maps/fusioncharts.greece';
import Sweden from 'fusioncharts/maps/fusioncharts.sweden';

import Heatmap from 'fusioncharts/fusioncharts.powercharts';

import StackedColumn2d from 'fusioncharts/fusioncharts.charts';
import MsColumn2d from 'fusioncharts/fusioncharts.charts';
import Column2d from 'fusioncharts/fusioncharts.charts';
import OverlappedColumn2d from 'fusioncharts/fusioncharts.charts';
import Line2d from 'fusioncharts/fusioncharts.charts';
import AngularGauge from 'fusioncharts/fusioncharts.charts';

import TimeSeries from "fusioncharts/fusioncharts.timeseries";

import Widgets from "fusioncharts/fusioncharts.widgets";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";


import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueApexCharts from 'vue-apexcharts'

import GoogleAuth from './services/google_oAuth.js'
const gauthOption = {
  clientId: '231895297038-rrtr3p8g14bq4qt3gpqnhf1pamo323vp.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account',
  popup: false,
}
Vue.use(GoogleAuth, gauthOption)

import VueAppleLogin from 'vue-apple-login';
Vue.use(VueAppleLogin, {
  clientId: 'com.exeger.portal',
  // redirectURI: 'https://portal.exeger.com',
  redirectURI: 'https://example.com',
  // redirectURI: 'https://interactivehome.ddns.net',
  responseType: 'code id_token',
  scope: 'name email',
  responseMode: 'form_post',
  state: 'user authentication',
  nonce: 'user session',
  usePopup: true,
});

// import JsonExcel from "vue-json-excel";
import globals from './globals/definitions';

Vue.component('apexchart', VueApexCharts)
// Vue.component("downloadExcel", JsonExcel)
// Vue.use(VueFusionCharts, FusionCharts, Charts, Overlappedcolumn2d)
// Vue.use(VueApexCharts)
Vue.use(VueFusionCharts, FusionCharts, Column2d, Line2d, AngularGauge,
             MsColumn2d, StackedColumn2d, OverlappedColumn2d, TimeSeries,
             Maps, World, Europe, Greece, Sweden,
             Heatmap, Widgets, FusionTheme);
Vue.config.productionTip = false
Vue.prototype.$globals = globals

FusionCharts.options.license({
  key: 'osC4C-9bhC2C5A5D7D7A5C4D3I4C2A4D4E2oE-11pG1H4yamqfD4C4ucG-7C8D5B5D1D1D1F1F1H4C11B1B6E4B2C1C1kvvB1E6B1B-9B4H3A33svaD8B1B4oixB2A16A7cegE3A3H2B3A3B9B3B5D2E2F1xrrB8C2YE6bfuC3B10C6lE-13F4PB4H3uufE4F4C2G5G4E3I3C4B6E5B3C3E4B4I-8==',
  creditLabel: false,
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
