import AuthService from "../services/auth.service.js";

const invalidUser = localStorage.getItem("user") == "undefined" || localStorage.getItem("user") == "";
const user = JSON.parse(invalidUser ? null : localStorage.getItem("user"));

const initialState = user != "{}" ? { status: { loggedIn: true }, user } : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    register({ commit }, user) {
      return AuthService.signup(user).then(
        (response) => {
          commit("registerSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },
    login({ commit }, user) {
      return AuthService.login(user).then(
        (data) => {
          if (data.status == 401) {
            commit("loginFailure");
            return Promise.reject("Bad credentials");
          }
          commit("loginSuccess", data);
          return Promise.resolve(data);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit("logout");
      localStorage.setItem("user", "");
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
  },
};
