<template>
  <v-container>
    <v-row class="alert-container">
      <v-alert v-if="showMessage" class="alert" :type="messageType" dark>
        {{ message }}
      </v-alert>
    </v-row>
    <h1 class="title">Customer</h1>
    <v-row justify="center">
      <v-col cols="4">
        <v-fab-transition>
          <v-card class="mx-auto">
            <v-card-title style="fontSize: 1em; white-space: nowrap; overflow: hidden; text-overflow: clip; ">
              Search by customer name
            </v-card-title>
            <v-card-text>
              <v-combobox
                autofocus
                :filter="filter"
                v-model="customerName"
                hint="Start typing to search or add customer"
                :items="customersNames"
                :hide-no-data="!searchCustomer"
                small-chips
                label="Customer name"
                :search-input.sync="searchCustomer"
                hide-selected
                @change="onChangeCustomer"
                ><template v-slot:no-data>
                  <v-list-item>
                    <span class="subheading"
                      >Add customer
                      <v-chip label small>{{ searchCustomer }}</v-chip>
                    </span>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-card-text>
          </v-card>
        </v-fab-transition>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogAddCustomer" max-width="40%">
      <v-card>
        <v-card-title class="delete-text">{{confirmationTitle}}</v-card-title>
        <v-card-text>{{confirmationText}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeAddCustomer">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="addCustomerConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row justify="center">
      <v-col cols="8">
        <v-data-table
          v-show="(customerName != null && customerName != '') || newCustomer"
          :headers="headers"
          :items="users"
          :search="search"
          sort-by="firstname"
          class="elevation-4"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="7">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search user"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialogAddEdit" width="50%">
                <template v-slot:activator="{ on }">
                  <v-btn elevation="7" color="primary" dark class="mb-2" v-on="on" fab>
                    <v-icon>{{ addUserIcon }}</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="6" sm="6" md="6">
                          <v-text-field
                            autofocus
                            tabindex="1"
                            v-model="editedUser.firstname"
                            label="First name"
                            :error-messages="firstnameErrors"
                            :counter="15"
                            @input="$v.editedUser.firstname.$touch()"
                            @blur="$v.editedUser.firstname.$touch()"
                          ></v-text-field>
                        </v-col>
                        <v-col v-if="editedIndex === -1" cols="6" sm="6" md="6">
                          <v-text-field
                            tabindex="4"
                            v-model="editedUser.password"
                            label="Password"
                            :error-messages="passwordErrors"
                            @input="$v.editedUser.password.$touch()"
                            @blur="$v.editedUser.password.$touch()"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="6" md="6">
                          <v-text-field
                            tabindex="2"
                            v-model="editedUser.lastname"
                            label="Last name"
                            :error-messages="lastnameErrors"
                            :counter="15"
                            @input="$v.editedUser.lastname.$touch()"
                            @blur="$v.editedUser.lastname.$touch()"
                          ></v-text-field>
                        </v-col>
                        <v-col v-if="editedIndex === -1" cols="6" sm="6" md="6">
                          <v-text-field
                            tabindex="5"
                            v-model="editedUser.confirmPassword"
                            label="Confirm Password"
                            :error-messages="confirmPasswordErrors"
                            @input="$v.editedUser.confirmPassword.$touch()"
                            @blur="$v.editedUser.confirmPassword.$touch()"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="6" md="6">
                          <v-text-field
                            tabindex="3"
                            v-model="editedUser.email"
                            label="Email"
                            :error-messages="emailErrors"
                            @input="$v.editedUser.email.$touch()"
                            @blur="$v.editedUser.email.$touch()"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="6" md="6">
                          <v-select
                            tabindex="6"
                            v-model="editedUser.roles"
                            label="Roles"
                            small-chips
                            multiple
                            deletable-chips
                            :items="rolesAvailable"
                            :error-messages="rolesErrors"
                            @input="$v.editedUser.roles.$touch()"
                            @blur="$v.editedUser.roles.$touch()"
                          ></v-select>
                        </v-col>
                        <v-col cols="6" sm="6" md="6">
                          <v-text-field
                            tabindex="7"
                            v-model="editedUser.expireDays"
                            label="Expire in Days"
                            hint="Number of days. -1 means never expire"
                            :error-messages="expireDaysErrors"
                            @input="onExpireDays"
                            @blur="$v.editedUser.expireDays.$touch()"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="6" md="6">
                          <v-text-field
                            readonly
                            v-model="editedUser.expireDatetimeStr"
                            label="Expiration Date"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="6" md="6">
                          <v-text-field
                            tabindex="8"
                            v-model="editedUser.exegerContact"
                            label="Contact person email"
                            :error-messages="exegerContactErrors"
                            @input="$v.editedUser.exegerContact.$touch()"
                            @blur="$v.editedUser.exegerContact.$touch()"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancel
                    </v-btn>
                    <v-btn :disabled="!editedUserValid" color="blue darken-1" text @click="save">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="40%">
                <v-card>
                  <v-card-title class="delete-text">Are you sure you want to delete this user?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteUserConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.roles="{ item }">
            <v-select v-model="item.roles" :items="rolesAvailable" small-chips multiple readonly></v-select>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editUserDialog(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteUserDialog(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, minLength, email, sameAs, numeric } from "vuelidate/lib/validators";
// import {addDays} from '../utils/datetime.js';

export default {
  mixins: [validationMixin],
  validations: {
    editedUser: {
      firstname: { required, maxLength: maxLength(15) },
      lastname: { required, maxLength: maxLength(15) },
      email: { required, email },
      password: { required, minLength: minLength(8) },
      confirmPassword: { sameAsPassword: sameAs("password") },
      roles: { required },
      expireDays: { required, numeric },
      exegerContact: { required, email },
    },
  },
  data() {
    return {
      dialogAddEdit: false,
      dialogDelete: false,

      dialogAddCustomer: false,
      confirmationTitle: "",
      confirmationText: "",

      searchCustomer: "",
      search: "",
      editedIndex: -1,
      editedUser: {
        id: null,
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        confirmPassword: "",
        roles: ["user"],
        expireDays: -1,
        expireDatetime: null,
        expireDatetimeStr: "Never",
        exegerContact: "",
      },
      defaultUser: {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        confirmPassword: "",
        roles: ["user"],
        expireDays: -1,
        expireDatetime: null,
        expireDatetimeStr: "Never",
        exegerContact: "",
      },

      customerName: "",
      comments: null,
      newCustomer: false,
      newUser: false,
      // addCustomerIcon: "mdi-plus",
      addUserIcon: "mdi-plus",
      customers: [],
      customersNames: [],
      loadingUsers: false,
      page: 1,
      pageCount: 0,
      headers: [
        { text: "First name", value: "firstname" },
        { text: "Last name", value: "lastname" },
        { text: "Email", value: "email" },
        { text: "Roles", value: "roles", sortable: false },
        { text: "Expiration Date", value: "expireDatetimeStr" },
        { text: "Exeger contact", value: "exegerContact" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      users: [],
      rolesAvailable: ["admin", "power user", "user"],
      rules: {
        notEmpty: [(v) => !!v || "Field is required!"],
        email: [(v) => !!v || "E-mail is required!", (v) => /.+@.+\..+[a-zA-Z]{2,}/.test(v) || "E-mail must be valid!"],
      },
      expirationDate: null,
      message: null,
      messageType: null,
      showMessage: false,
    };
  },
  computed: {
    editedUserValid() {
      return this.editedUser != -1
        ? true
        : this.editedUser.firstname.length != 0 &&
            this.editedUser.lastname.length != 0 &&
            this.editedUser.email.length != 0 &&
            this.editedUser.password.length != 0 &&
            this.editedUser.confirmPassword.length != 0 &&
            this.editedUser.roles.length != 0 &&
            this.editedUser.expireDays.length != 0 &&
            this.firstnameErrors.length == 0 &&
            this.lastnameErrors.length == 0 &&
            this.emailErrors.length == 0 &&
            this.passwordErrors.length == 0 &&
            this.confirmPasswordErrors.length == 0 &&
            this.rolesErrors.length == 0 &&
            this.expireDaysErrors.length == 0;
    },
    accessToken() {
      return JSON.parse(localStorage.getItem("user")).accessToken;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New User" : "Edit User";
    },
    firstnameErrors() {
      const errors = [];
      if (!this.$v.editedUser.firstname.$dirty) return errors;
      !this.$v.editedUser.firstname.maxLength && errors.push("First name must be at most 15 characters long");
      !this.$v.editedUser.firstname.required && errors.push("First name is required");
      return errors;
    },
    lastnameErrors() {
      const errors = [];
      if (!this.$v.editedUser.lastname.$dirty) return errors;
      !this.$v.editedUser.lastname.maxLength && errors.push("Last name must be at most 15 characters long");
      !this.$v.editedUser.lastname.required && errors.push("Last name is required");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.editedUser.email.$dirty) return errors;
      !this.$v.editedUser.email.email && errors.push("Must be valid email");
      !this.$v.editedUser.email.required && errors.push("Email is required");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.editedUser.password.$dirty) return errors;
      !this.$v.editedUser.password.minLength && errors.push("Password must be at least 8 characters long");
      !this.$v.editedUser.password.required && errors.push("Password is required");
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.editedUser.confirmPassword.$dirty) return errors;
      !this.$v.editedUser.confirmPassword.sameAsPassword && errors.push("Must match with password");
      return errors;
    },
    rolesErrors() {
      const errors = [];
      if (!this.$v.editedUser.roles.$dirty) return errors;
      !this.$v.editedUser.roles.required && errors.push("At least one role must be assigned to the user");
      return errors;
    },
    expireDaysErrors() {
      const errors = [];
      if (!this.$v.editedUser.expireDays.$dirty) return errors;
      !this.$v.editedUser.expireDays.numeric && errors.push("Must be a number");
      !this.$v.editedUser.expireDays.required && errors.push("Expire in Days is required. -1 means never expire");
      return errors;
    },
    exegerContactErrors() {
      const errors = [];
      if (!this.$v.editedUser.exegerContact.$dirty) return errors;
      !this.$v.editedUser.exegerContact.email && errors.push("Must be valid email");
      !this.$v.editedUser.exegerContact.required && errors.push("Contact person email is required");
      return errors;
    },
  },

  watch: {
    dialogAddEdit(val) {
      val || this.close();
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    // customerName(val) {
    //   if (val === null || val === "") return;
    //   if (this.verifyAddCustomer(val)) {
    //     this.customersNames.push(val);
    //   }
    // },
    // editedUser: {
    //   expireDays(val) {
    //     console.log('expireDays changed to: ', val);
    //     if (val != null) {
    //       console.log("expireDays", val);
    //       let expDate = new Date();
    //       this.editedUser.expireDatetimeStr = expDate;
    //     }
    //   },
    // },
  },

  created() {
    this.fetchCustomers();
  },

  methods: {
    async fetchCustomers() {
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      reqEndpoint = this.$globals.API_URL + this.$globals.RES_CUSTOMER_BRAND;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      data.forEach((customer) => {
        if (customer.name != null && customer.name != "") {
          this.customers.push(customer);
          this.customersNames.push(customer.name);
        }
      });
    },

    async fetchCustomerUsers() {
      this.users = [];
      this.loadingUsers = true;
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      reqEndpoint = this.$globals.API_URL + this.$globals.RES_USER + `?customerName=${this.customerName}`;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      const data = await response.json();
      let users = data;
      users.forEach((user) => {
        let roles = [];
        user.roles.forEach((role) => {
          if (role == "ROLE_ADMIN") {
            roles.push("admin");
          }
          if (role == "ROLE_POWER_USER") {
            roles.push("power user");
          }
          if (role == "ROLE_USER") {
            roles.push("user");
          }
        });
        user.roles = roles;
        if (user.expire_at_utc == null || user.expire_at_utc == -1) {
          user.expireDatetimeStr = "Never";
        } else {
          const expireDt = new Date(user.expire_at_utc);
          user.expireDatetime = expireDt;
          user.expireDatetimeStr = expireDt.toLocaleString("sv-SE");
        }
        console.log('exeger_contact: ', user.exeger_contact);
        user.exegerContact = user.exeger_contact;
        this.users.push(user);
      });
      this.loadingUsers = false;
    },

    onChangeCustomer() {
      this.users = [];
      if (this.customersNames.includes(this.customerName)) {
        this.fetchCustomerUsers();
      } else {
        this.verifyAddCustomer(this.customerName);
      }
    },

    // onAddCustomer() {
    //   this.customerName = null;
    //   this.newCustomer = !this.newCustomer;
    //   this.registerCustomer();
      // this.addCustomerIcon = this.newCustomer ? "mdi-minus" : "mdi-plus";
    // },

    async registerCustomer() {
      let response;
      let data;
      try {
        const myHeaders = new Headers();
        var payload = JSON.stringify({
          name: this.customerName,
          // comments: this.comments,
        });

        myHeaders.append("Authorization", "Bearer " + this.accessToken);
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: payload,
        };

        let reqEndpoint = "";
        reqEndpoint = this.$globals.API_URL + this.$globals.RES_CUSTOMER_BRAND;
        response = await fetch(reqEndpoint, requestOptions);
        data = await response.json();
      } catch (e) {
        console.error(e);
      }
      // console.log(JSON.stringify(data));
      this.messageType = "error";
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      } else if (response.status == 201) {
        this.messageType = "success";
      }
      this.showMessage = true;
      this.message = data.message;
      window.setTimeout(() => {
        this.showMessage = false;
      }, 3000);
    },

    onExpireDays() {
      this.$v.editedUser.expireDays.$touch();
      if (this.editedUser.expireDaysErrors != null) {
        return;
      }
      if (parseInt(this.editedUser.expireDays) == -1) {
        this.editedUser.expireDatetime = null;
        this.editedUser.expireDatetimeStr = "Never";
      } else {
        this.editedUser.expireDatetime = new Date();
        this.editedUser.expireDatetime.setDate(
          this.editedUser.expireDatetime.getDate() + parseInt(this.editedUser.expireDays)
        );
        this.editedUser.expireDatetime.setHours(23);
        this.editedUser.expireDatetime.setMinutes(59);
        this.editedUser.expireDatetime.setSeconds(59);
        this.editedUser.expireDatetimeStr = this.editedUser.expireDatetime.toLocaleString("sv-SE");
      }
    },

    async registerUser() {
      let userRoles = [];
      // console.log("roles: ", this.editedUser.roles);
      for (let i = 0; i < this.editedUser.roles.length; i++) {
        if (this.editedUser.roles[i] == "admin") {
          userRoles.push("ROLE_ADMIN");
        }
        if (this.editedUser.roles[i] == "power user") {
          userRoles.push("ROLE_POWER_USER");
        }
        if (this.editedUser.roles[i] == "user") {
          userRoles.push("ROLE_USER");
        }
      }
      const myHeaders = new Headers();
      var payload = JSON.stringify({
        firstname: this.editedUser.firstname,
        lastname: this.editedUser.lastname,
        email: this.editedUser.email,
        password: this.editedUser.password,
        roles: userRoles,
        customer_name: this.customerName,
        exeger_contact: this.editedUser.exegerContact,
        expire_at_utc: this.editedUser.expireDatetime == null ? null : this.editedUser.expireDatetime.toISOString(),
      });

      // console.log("user: ", payload);

      myHeaders.append("Authorization", "Bearer " + this.accessToken);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Accept", "application/json");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: payload,
      };

      let reqEndpoint = "";
      reqEndpoint = this.$globals.API_URL + this.$globals.RES_USER;
      const response = await fetch(reqEndpoint, requestOptions);
      const data = await response.json();
      this.messageType = "error";
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      } else if (response.status == 201) {
        this.messageType = "success";
        this.users = [];
        this.fetchCustomerUsers();
      }
      this.showMessage = true;
      this.message = data.message;
      window.setTimeout(() => {
        this.showMessage = false;
      }, 3000);
    },

    async modifyUser() {
      let userRoles = [];
      // console.log("roles: ", this.editedUser.roles);
      for (let i = 0; i < this.editedUser.roles.length; i++) {
        if (this.editedUser.roles[i] == "admin") {
          userRoles.push("ROLE_ADMIN");
        }
        if (this.editedUser.roles[i] == "power user") {
          userRoles.push("ROLE_POWER_USER");
        }
        if (this.editedUser.roles[i] == "user") {
          userRoles.push("ROLE_USER");
        }
      }
      const myHeaders = new Headers();
      var payload = JSON.stringify({
        firstname: this.editedUser.firstname,
        lastname: this.editedUser.lastname,
        email: this.editedUser.email,
        roles: userRoles,
        customer_name: this.customerName,
        exeger_contact: this.editedUser.exegerContact,
        expire_at_utc: this.editedUser.expireDatetime == null ? null : this.editedUser.expireDatetime.toISOString(),
      });

      // console.log("user: ", payload);

      myHeaders.append("Authorization", "Bearer " + this.accessToken);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Accept", "application/json");
      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: payload,
      };

      let reqEndpoint = "";
      // console.log("id: ", this.editedUser.id);
      reqEndpoint = this.$globals.API_URL + this.$globals.RES_USER + "/" + this.editedUser.id;
      const response = await fetch(reqEndpoint, requestOptions);
      const data = await response.json();
      this.messageType = "error";
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      } else if (response.status == 200) {
        this.messageType = "success";
        // this.fetchCustomerUsers();
      }
      this.showMessage = true;
      this.message = data.message;
      window.setTimeout(() => {
        this.showMessage = false;
      }, 3000);
    },

    async deleteUser() {
      const myHeaders = new Headers();

      myHeaders.append("Authorization", "Bearer " + this.accessToken);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Accept", "application/json");
      var requestOptions = {
        method: "DELETE",
        headers: myHeaders,
      };

      let reqEndpoint = "";
      // console.log("id: ", this.editedUser.id);
      reqEndpoint = this.$globals.API_URL + this.$globals.RES_USER + "/" + this.editedUser.id;
      const response = await fetch(reqEndpoint, requestOptions);
      const data = await response.json();
      this.messageType = "error";
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      } else if (response.status == 200) {
        this.messageType = "success";
      }
      this.showMessage = true;
      this.message = data.message;
      window.setTimeout(() => {
        this.showMessage = false;
      }, 3000);
    },

    // selectDefaultRole() {
    //   this.editedUser.roles = "user";
    // },

    calculateNumberOfDaysToExpire(date) {
      if (date == null) {
        return -1;
      }
      const today = new Date();
      return Math.floor((date.getTime() - today.getTime()) / 1000 / 3600 / 24);
      //                                             ^        ^        ^
      //                                          seconds    hours    days
    },

    editUserDialog(item) {
      // const expireDays = this.calculateNumberOfDaysToExpire(item.expireDatetime);
      // console.log('expireDays: ', expireDays);
      this.editedIndex = this.users.indexOf(item);
      this.editedUser = Object.assign({}, item);
      this.editedUser.expireDays = this.calculateNumberOfDaysToExpire(item.expireDatetime);
      this.dialogAddEdit = true;
    },

    deleteUserDialog(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedUser = Object.assign({}, item);
      this.dialogDelete = true;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedUser = Object.assign({}, this.defaultUser);
        this.editedIndex = -1;
      });
    },

    deleteUserConfirm() {
      this.users.splice(this.editedIndex, 1);
      this.closeDelete();
      this.deleteUser();
    },

    close() {
      this.dialogAddEdit = false;
      this.$nextTick(() => {
        this.editedUser = Object.assign({}, this.defaultUser);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], this.editedUser);
        this.modifyUser();
      } else {
        this.registerUser();
      }
      this.close();
    },

    verifyAddCustomer(val) {
      if (val.length > 0) {
        this.confirmationTitle = "Confirm New Customer";
        this.confirmationText = "Do you want to add the new customer " + val;
        this.dialogAddCustomer = true;
      }
    },

    addCustomerConfirm() {
      this.dialogAddCustomer = false;
      this.customersNames.push(this.customerName);
      this.registerCustomer();
    },

    closeAddCustomer() {
      this.dialogAddCustomer = false;
      this.customerName = "";
    },

    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
    },
  },
};
</script>

<style>
.alert-container {
  width: 100%;
  position: absolute;
}
.alert {
  width: 100%;
}
.title {
  text-align: center;
  margin: 10px;
}
.fullwidth {
  width: 100%;
}
.absolute {
  position: absolute;
}
.delete-text {
  font-size: 10px;
}
</style>
