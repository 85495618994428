<template>
  <fusioncharts
    :type="type"
    :width="width"
    :height="height"
    :dataFormat="dataFormat"
    :dataSource="dataSource"
    ref="heatMapRef"
  ></fusioncharts>
</template>

<script>
export default {
  name: "HeatMap",
  props: {
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    title: {
      type: String,
    },
    data: {
      type: Array,
    },
  },
  data: function() {
    return {
      type: "heatmap",
      dataFormat: "json",
      dataSource: {
        chart: {
          theme: "fusion",
          bgColor: "#f7f7f7",
          caption: this.title,
          subcaption: "Values in mAh",
          xAxisName: "Customers",
          yAxisName: "Device Type",
          showValues: "1",
          showPlotBorder: "1",
          mapByCategory: "1",
        },
        rows: {
          row: [
            {
              id: "hp",
              label: "Headphones",
            },
            {
              id: "hl",
              label: "Helmet",
            },
            {
              id: "sk",
              label: "Ski",
            },
            {
              id: "dt",
              label: "Dog harness",
            },
          ],
        },
        columns: {
          column: [
            {
              id: "WI",
              label: "Urbanista",
            },
            {
              id: "SP",
              label: "Adidas",
            },
            {
              id: "SU",
              label: "3M",
            },
            {
              id: "AU",
              label: "Luxotica",
            },
          ],
        },
        dataset: [
          {
            data: this.data,
          },
        ],
        colorRange: {
          gradient: "0",
          color: [
            {
              code: "#6da81e",
              minValue: "70",
              maxValue: "85",
              label: "Very Good",
            },
            {
              code: "#f6bc33",
              minValue: "50",
              maxValue: "70",
              label: "Average",
            },
            {
              code: "#e24b1a",
              minValue: "0",
              maxValue: "50",
              label: "Bad",
            },
          ],
        },
      },
    };
  },
};
</script>

<style></style>
