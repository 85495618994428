<template>
  <fusioncharts
    :type="type"
    :width="width"
    :height="height"
    :dataFormat="dataFormat"
    :dataSource="dataSource"
    ref="europeMapRef"
  ></fusioncharts>
</template>

<script>
export default {
  name: "EuropeMap",
  props: {
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    title: {
      type: String,
    },
    data: {
      type: Array,
    },
  },
  data: function() {
    return {
      type: "world",
      dataFormat: "json",
      dataSource: {
        chart: {
          usehovercolor: "1",
          caption: "Rio 2016 Olympic Medals Tally",
          subcaption: "(Click on regions for more information)",
          theme: "fusion",
          entityfillhovercolor: "#FFF9C4",
          entitytooltext: "$lname has won total of <b>$dataValue</b> medals",
          legendcaption: "Medals count",
        },
        colorrange: {
          gradient: "1",
          minvalue: "0",
          code: "#F9F3DD",
          color: [
            {
              maxvalue: "150",
              code: "#F5C592",
            },
            {
              maxvalue: "300",
              code: "#E79C57",
            },
            {
              maxvalue: "500",
              code: "#B34302",
            },
          ],
        },
        data: [
          {
            data: [
              {
                id: "SA",
                value: "60",
                link: "newchart-json-sa",
              },
              {
                id: "NA",
                value: "150",
                link: "newchart-json-na",
              },
              {
                id: "EU",
                value: "442",
                link: "newchart-json-eu",
              },
              {
                id: "AS",
                value: "226",
                link: "newchart-json-as",
              },
              {
                id: "AU",
                value: "48",
                link: "newchart-json-au",
              },
              {
                id: "AF",
                value: "45",
                link: "newchart-json-af",
              },
            ],
          },
        ],
        linkeddata: [
          {
            id: "na",
            linkedchart: {
              chart: {
                caption: "Rio 2016 Medal Distribution - North America",
                showvalues: "1",
                theme: "fusion",
                palettecolors: "#FED417,#B9BEBF,#D18A30",
                plottooltext: "$seriesname: $datavalue",
              },
              categories: [
                {
                  category: [
                    {
                      label: "United States",
                    },
                    {
                      label: "Canada",
                    },
                    {
                      label: "Mexico",
                    },
                  ],
                },
              ],
              dataset: [
                {
                  seriesname: "Gold",
                  data: [
                    {
                      value: "37",
                    },
                    {
                      value: "4",
                    },
                    {
                      value: "0",
                    },
                  ],
                },
                {
                  seriesname: "Silver",
                  data: [
                    {
                      value: "38",
                    },
                    {
                      value: "3",
                    },
                    {
                      value: "3",
                    },
                  ],
                },
                {
                  seriesname: "Bronze",
                  data: [
                    {
                      value: "53",
                    },
                    {
                      value: "15",
                    },
                    {
                      value: "2",
                    },
                  ],
                },
              ],
            },
          },
          {
            id: "sa",
            linkedchart: {
              chart: {
                caption: "Rio 2016 Medal Distribution - South America",
                plottooltext: "$seriesname: $datavalue",
                palettecolors: "#FED417,#B9BEBF,#D18A30",
                showvalues: "1",
                placevaluesinside: "0",
                theme: "fusion",
              },
              categories: [
                {
                  category: [
                    {
                      label: "Brazil",
                    },
                    {
                      label: "Argentina",
                    },
                    {
                      label: "Cuba",
                    },
                  ],
                },
              ],
              dataset: [
                {
                  seriesname: "Gold",
                  data: [
                    {
                      value: "7",
                    },
                    {
                      value: "3",
                    },
                    {
                      value: "5",
                    },
                  ],
                },
                {
                  seriesname: "Silver",
                  data: [
                    {
                      value: "6",
                    },
                    {
                      value: "1",
                    },
                    {
                      value: "2",
                    },
                  ],
                },
                {
                  seriesname: "Bronze",
                  data: [
                    {
                      value: "6",
                    },
                    {
                      value: "0",
                    },
                    {
                      value: "4",
                    },
                  ],
                },
              ],
            },
          },
          {
            id: "as",
            linkedchart: {
              chart: {
                caption: "Rio 2016 Medal Distribution - Asia",
                plottooltext: "$seriesname: $datavalue",
                showvalues: "1",
                palettecolors: "#FED417,#B9BEBF,#D18A30",
                theme: "fusion",
              },
              categories: [
                {
                  category: [
                    {
                      label: "China",
                    },
                    {
                      label: "Japan",
                    },
                    {
                      label: "South Korea",
                    },
                  ],
                },
              ],
              dataset: [
                {
                  seriesname: "Gold",
                  data: [
                    {
                      value: "26",
                    },
                    {
                      value: "12",
                    },
                    {
                      value: "9",
                    },
                  ],
                },
                {
                  seriesname: "Silver",
                  data: [
                    {
                      value: "18",
                    },
                    {
                      value: "8",
                    },
                    {
                      value: "3",
                    },
                  ],
                },
                {
                  seriesname: "Bronze",
                  data: [
                    {
                      value: "26",
                    },
                    {
                      value: "21",
                    },
                    {
                      value: "9",
                    },
                  ],
                },
              ],
            },
          },
          {
            id: "af",
            linkedchart: {
              chart: {
                caption: "Rio 2016 Medal Distribution - Africa",
                showvalues: "1",
                palettecolors: "#FED417,#B9BEBF,#D18A30",
                plottooltext: "$seriesname: $datavalue",
                theme: "fusion",
              },
              categories: [
                {
                  category: [
                    {
                      label: "Kenya",
                    },
                    {
                      label: "South Africa",
                    },
                    {
                      label: "Ethiopia",
                    },
                  ],
                },
              ],
              dataset: [
                {
                  seriesname: "Gold",
                  data: [
                    {
                      value: "6",
                    },
                    {
                      value: "2",
                    },
                    {
                      value: "1",
                    },
                  ],
                },
                {
                  seriesname: "Silver",
                  data: [
                    {
                      value: "6",
                    },
                    {
                      value: "6",
                    },
                    {
                      value: "2",
                    },
                  ],
                },
                {
                  seriesname: "Bronze",
                  data: [
                    {
                      value: "1",
                    },
                    {
                      value: "2",
                    },
                    {
                      value: "5",
                    },
                  ],
                },
              ],
            },
          },
          {
            id: "au",
            linkedchart: {
              chart: {
                caption: "Rio 2016 Medal Distribution - Australia",
                showvalues: "1",
                palettecolors: "#FED417,#B9BEBF,#D18A30",
                plottooltext: "$seriesname: $datavalue",
                theme: "fusion",
              },
              categories: [
                {
                  category: [
                    {
                      label: "Australia",
                    },
                    {
                      label: "New Zealand",
                    },
                  ],
                },
              ],
              dataset: [
                {
                  seriesname: "Gold",
                  data: [
                    {
                      value: "8",
                    },
                    {
                      value: "4",
                    },
                  ],
                },
                {
                  seriesname: "Silver",
                  data: [
                    {
                      value: "11",
                    },
                    {
                      value: "9",
                    },
                  ],
                },
                {
                  seriesname: "Bronze",
                  data: [
                    {
                      value: "10",
                    },
                    {
                      value: "5",
                    },
                  ],
                },
              ],
            },
          },
          {
            id: "eu",
            linkedchart: {
              chart: {
                caption: "Rio 2016 Medal Distribution - Europe",
                showvalues: "1",
                palettecolors: "#FED417,#B9BEBF,#D18A30",
                plottooltext: "$seriesname: $datavalue",
                theme: "fusion",
              },
              categories: [
                {
                  category: [
                    {
                      label: "Great Britain",
                    },
                    {
                      label: "Russia",
                    },
                    {
                      label: "Germany",
                    },
                  ],
                },
              ],
              dataset: [
                {
                  seriesname: "Gold",
                  data: [
                    {
                      value: "27",
                    },
                    {
                      value: "19",
                    },
                    {
                      value: "17",
                    },
                  ],
                },
                {
                  seriesname: "Silver",
                  data: [
                    {
                      value: "23",
                    },
                    {
                      value: "18",
                    },
                    {
                      value: "10",
                    },
                  ],
                },
                {
                  seriesname: "Bronze",
                  data: [
                    {
                      value: "17",
                    },
                    {
                      value: "19",
                    },
                    {
                      value: "15",
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style></style>
