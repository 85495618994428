<template>
  <v-container>
    <v-row>
      <v-col class="heat-map" cols="12" sm="12" md="4" lg="4" xl="4">
        <heat-map-chart
          v-bind:width="x"
          v-bind:height="y"
          v-bind:data="heatMapEuropeData"
          v-bind:title="heatMapEuropeTitle"
        />
      </v-col>
      <v-col class="heat-map" cols="12" sm="12" md="4" lg="4" xl="4">
        <heat-map-chart
          v-bind:width="x"
          v-bind:height="y"
          v-bind:data="heatMapUSData"
          v-bind:title="heatMapUSTitle"
        />
      </v-col>
      <v-col class="heat-map" cols="12" sm="12" md="4" lg="4" xl="4">
        <heat-map-chart
          v-bind:width="x"
          v-bind:height="y"
          v-bind:data="heatMapSouthAmericaData"
          v-bind:title="heatMapSouthAmericaTitle"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="world-map" cols="12">
        <world-map-chart v-bind:width="x" v-bind:height="y" v-bind:data="worldMapData" v-bind:title="worldMapTitle" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import WorldMapChart from "../charts/WorldMap.vue";
import HeatMapChart from "../charts/HeatMapChart.vue";

export default {
  name: "PowerUserDashboard",
  props: {
    width: {
      type: String,
    },
    height: {
      type: String,
    },
  },
  components: {
    WorldMapChart,
    HeatMapChart,
  },
  data: () => ({
    windowSize: {
      x: 0,
      y: 0,
    },
    x: "100%",
    y: "48%",
    worldMapTitle: "Worldwide Gain-Drain total values",
    heatMapEuropeTitle: "Gain values in Europe",
    heatMapUSTitle: "Gain values in US",
    heatMapSouthAmericaTitle: "Gain values in South America",
    worldMapData: [
      {
        id: "NA",
        value: "57.2",
      },
      {
        id: "SA",
        value: "57.1",
      },
      {
        id: "AS",
        value: "290",
      },
      {
        id: "EU",
        value: "188.5",
      },
      {
        id: "AF",
        value: "87.2",
      },
      {
        id: "AU",
        value: "29",
      },
    ],
    heatMapEuropeData: [
      {
        rowid: "hp",
        columnid: "WI",
        value: "60.10",
        colorRangeLabel: "Average",
      },
      {
        rowid: "hp",
        columnid: "SP",
        displayValue: "64.5",
        colorRangeLabel: "Average",
      },
      {
        rowid: "hp",
        columnid: "SU",
        displayValue: "68.2",
        colorRangeLabel: "Average",
      },
      {
        rowid: "hp",
        columnid: "AU",
        displayValue: "65.7",
        colorRangeLabel: "Average",
      },
      {
        rowid: "hl",
        columnid: "WI",
        displayValue: "33.7",
        colorRangeLabel: "Bad",
      },
      {
        rowid: "hl",
        columnid: "SP",
        displayValue: "57.8",
        colorRangeLabel: "Average",
      },
      {
        rowid: "hl",
        columnid: "SU",
        displayValue: "74.49",
        colorRangeLabel: "Very Good",
      },
      {
        rowid: "hl",
        columnid: "AU",
        displayValue: "57.6",
        colorRangeLabel: "Average",
      },
      {
        rowid: "sk",
        columnid: "WI",
        displayValue: "22.89",
        colorRangeLabel: "Bad",
      },
      {
        rowid: "sk",
        columnid: "SP",
        displayValue: "55.7",
        colorRangeLabel: "Average",
      },
      {
        rowid: "sk",
        columnid: "SU",
        displayValue: "72.2",
        colorRangeLabel: "Very Good",
      },
      {
        rowid: "sk",
        columnid: "AU",
        displayValue: "51.6",
        colorRangeLabel: "Average",
      },
      {
        rowid: "dt",
        columnid: "WI",
        displayValue: "53.0",
        colorRangeLabel: "Average",
      },
      {
        rowid: "dt",
        columnid: "SP",
        displayValue: "72.7",
        colorRangeLabel: "Very Good",
      },
      {
        rowid: "dt",
        columnid: "SU",
        displayValue: "83.3",
        colorRangeLabel: "Very Good",
      },
      {
        rowid: "dt",
        columnid: "AU",
        displayValue: "53.0",
        colorRangeLabel: "Average",
      },
    ],
    heatMapUSData: [
      {
        rowid: "hp",
        columnid: "WI",
        value: "60.10",
        colorRangeLabel: "Average",
      },
      {
        rowid: "hp",
        columnid: "SP",
        displayValue: "64.5",
        colorRangeLabel: "Average",
      },
      {
        rowid: "hp",
        columnid: "SU",
        displayValue: "68.2",
        colorRangeLabel: "Average",
      },
      {
        rowid: "hp",
        columnid: "AU",
        displayValue: "65.7",
        colorRangeLabel: "Average",
      },
      {
        rowid: "hl",
        columnid: "WI",
        displayValue: "33.7",
        colorRangeLabel: "Bad",
      },
      {
        rowid: "hl",
        columnid: "SP",
        displayValue: "57.8",
        colorRangeLabel: "Average",
      },
      {
        rowid: "hl",
        columnid: "SU",
        displayValue: "74.49",
        colorRangeLabel: "Very Good",
      },
      {
        rowid: "hl",
        columnid: "AU",
        displayValue: "57.6",
        colorRangeLabel: "Average",
      },
      {
        rowid: "sk",
        columnid: "WI",
        displayValue: "22.89",
        colorRangeLabel: "Bad",
      },
      {
        rowid: "sk",
        columnid: "SP",
        displayValue: "55.7",
        colorRangeLabel: "Average",
      },
      {
        rowid: "sk",
        columnid: "SU",
        displayValue: "72.2",
        colorRangeLabel: "Very Good",
      },
      {
        rowid: "sk",
        columnid: "AU",
        displayValue: "51.6",
        colorRangeLabel: "Average",
      },
      {
        rowid: "dt",
        columnid: "WI",
        displayValue: "53.0",
        colorRangeLabel: "Average",
      },
      {
        rowid: "dt",
        columnid: "SP",
        displayValue: "72.7",
        colorRangeLabel: "Very Good",
      },
      {
        rowid: "dt",
        columnid: "SU",
        displayValue: "83.3",
        colorRangeLabel: "Very Good",
      },
      {
        rowid: "dt",
        columnid: "AU",
        displayValue: "53.0",
        colorRangeLabel: "Average",
      },
    ],
    heatMapSouthAmericaData: [
      {
        rowid: "hp",
        columnid: "WI",
        value: "60.10",
        colorRangeLabel: "Average",
      },
      {
        rowid: "hp",
        columnid: "SP",
        displayValue: "64.5",
        colorRangeLabel: "Average",
      },
      {
        rowid: "hp",
        columnid: "SU",
        displayValue: "68.2",
        colorRangeLabel: "Average",
      },
      {
        rowid: "hp",
        columnid: "AU",
        displayValue: "65.7",
        colorRangeLabel: "Average",
      },
      {
        rowid: "hl",
        columnid: "WI",
        displayValue: "33.7",
        colorRangeLabel: "Bad",
      },
      {
        rowid: "hl",
        columnid: "SP",
        displayValue: "57.8",
        colorRangeLabel: "Average",
      },
      {
        rowid: "hl",
        columnid: "SU",
        displayValue: "74.49",
        colorRangeLabel: "Very Good",
      },
      {
        rowid: "hl",
        columnid: "AU",
        displayValue: "57.6",
        colorRangeLabel: "Average",
      },
      {
        rowid: "sk",
        columnid: "WI",
        displayValue: "22.89",
        colorRangeLabel: "Bad",
      },
      {
        rowid: "sk",
        columnid: "SP",
        displayValue: "55.7",
        colorRangeLabel: "Average",
      },
      {
        rowid: "sk",
        columnid: "SU",
        displayValue: "72.2",
        colorRangeLabel: "Very Good",
      },
      {
        rowid: "sk",
        columnid: "AU",
        displayValue: "51.6",
        colorRangeLabel: "Average",
      },
      {
        rowid: "dt",
        columnid: "WI",
        displayValue: "53.0",
        colorRangeLabel: "Average",
      },
      {
        rowid: "dt",
        columnid: "SP",
        displayValue: "72.7",
        colorRangeLabel: "Very Good",
      },
      {
        rowid: "dt",
        columnid: "SU",
        displayValue: "83.3",
        colorRangeLabel: "Very Good",
      },
      {
        rowid: "dt",
        columnid: "AU",
        displayValue: "53.0",
        colorRangeLabel: "Average",
      },
    ],
  }),

  mounted() {
    this.onResize();
  },

  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
  },
};
</script>

<style>
.world-map {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.heat-map {
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
  padding: 0px;
  margin-top: -8px;
}
</style>
