import globals from '../globals/definitions.js'

class AuthService {
  async signup(user) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var payload = JSON.stringify({
      "firstname": user.firstname,
      "lastname": user.lastname,
      // "username": user.email,
      "email": user.email,
      "password": user.password,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: payload,
      redirect: 'follow'
    };

    try {
      const response = await fetch(globals.API_URL + "/auth/signup", requestOptions);
      const data = await response.json();
      if (data.accessToken) {
        localStorage.setItem('user', JSON.stringify(data));
      }
      return data;
    } catch (error) {
      return console.log('error', error);
    }
  }
  async login(user) {
    var myHeaders = new Headers();

    var payload = JSON.stringify({
      "email": user.email,
      "password": user.password,
    });
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: payload,
      redirect: 'follow'
    };

    try {
      const response = await fetch(globals.API_URL + "/auth/signin", requestOptions);
      const data = await response.json();
      if (response.status == 200 && data.accessToken) {
        localStorage.setItem('user', JSON.stringify(data));
      }
      return data;
    } catch (error) {
      return console.log('error', error);
    }
  }
  logout() {
    localStorage.removeItem('user');
  }
}

export default new AuthService();
