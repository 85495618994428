<template>
  <fusioncharts
    :type="type"
    :width="width"
    :height="height"
    :loadMessage="loadingText"
    :dataFormat="dataFormat"
    :dataSource="dataSource"
    ref="chartRef"
  ></fusioncharts>
</template>

<script>
import FusionCharts from "fusioncharts";

export default {
  name: "TimeSeries",
  props: {
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    data: {
      type: Array,
    },
  },
  data: function() {
    return {
      gainDrainData: [],
      loadingData: false,
      loadingText: "This is loading............",
      type: "timeseries",
      loadMessage: "I-https://i.gifer.com/9viU.gif",
      loadMessageImageHAlign: "middle",
      loadMessageImageVAlign: "middle",
      loadStartMessageImageAlpha: 50,
      loadStartMessageImageScale: 100,
      dataLoadStartMessage: "<I-https://i.gifer.com/9viU.gif",
      dataLoadStartMessageImageHAlign: "middle",
      dataLoadStartMessageImageVAlign: "middle",
      dataLoadStartMessageImageAlpha: 50,
      dataLoadStartMessageImageScale: 100,
      dataFormat: "json",
      dataSource: {
        tooltip: {
          enabled: true,
          outputTimeFormat: {
            day: "%d/%m/%Y (%a)",
            hour: "%H:%M (%a)",
            minute: "%H:%M (%a)",
          },
        },
        navigator: {
          enabled: 1,
          // outputTimeFormat: "%B %Y %d - %H:%M",
          window: {
            style: {
              handle: {
                padding: "1px",
              },
              "handle-grip": {
                padding: "2px",
              },
              mask: {
                color: "#ffffff",
              },
            },
          },
        },
        styleDefinition: {
          "txt-red": {
            fill: "red",
          },
          "caption-size": {
            "font-size": 20,
          },
          "background-color": {
            // fill: "#f7f7f7",
            opacity: 0,
          },
        },
        caption: {
          text: "Gain-Drain Data",
          style: {
            text: "caption-size",
          },
        },
        data: [],
        subcaption: {
          text: "",
        },
        chart: {
          style: {
            // background : "background-color",
            // canvas: "background-color",
          },
          showlegend: 1,
          showtooltip: 1,
          // dataLoadStartMessage: "https://i.gifer.com/9viU.gif",
          exportEnabled: false,
          paletteColors: ["#337700", "#ff2703", "#ccaa00", "#132277"],
          loadMessage: "loading.............",
        },
        // xAxis: {
        //   // initialInterval: {
        //   //   from: "2021-01-01 12:00:00",
        //   //   to: "2021-01-31 12:00:00",
        //   // },
        //   outputTimeFormat: {
        //     year: " ", //%Y - 2018
        //     month: " ", //%B, %Y - September, 2018
        //     day: " ", //%B %-d, %Y - November 11, 2018
        //     hour: " ", //%b %-d, %Y, %-I %p - Nov 5, 2018, 4 PM
        //     minute: " ", //%b %-d, %Y, %-I:%-M %p - Nov 5, 2018, 4:24 PM
        //     second: " ", //%b %-d, %Y, %-I:%-M:%-S %p - Nov 5, 2018, 4:25:30 PM
        //     millisecond: " ", //%b %-d, %Y, %-I:%-M:%-S:%-L %p- Nov 29, 2017, 4:29:12.075 PM
        //   },
        // },
        xAxis: {
          // autoClipNullData: "day",
          // autoClipMultiplier: 1,
          binning: {
            outputTimeFormat: {
              day: "%d/%m/%Y (%a)",
              hour: "%H:%M (%a)",
              minute: "%H:%M (%a)",
            },
            year: [1], // Ex - [1,2,3,5,10]
            month: [1], // Ex - [1,2,3,4,6]
            day: [
              1,
              // 1, 2, 3, 4, 5, 6, 7, 8, 9,
              // 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
              // 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
              // 30, 31,
            ], // Ex - [1,2,3,5,6,10,15]
            hour: [
              1,
              // 1, 2, 3, 4, 5, 6, 7, 8, 9,
              // 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
              // 20, 21, 22, 23,
            ], // Ex - [1,2,3,4,6,8,12]
            minute: [
              2,
              // 0, 2, 4, 6, 8,
              // 10, 12, 14, 16, 18,
              // 20, 22, 24, 26, 28,
              // 30, 32, 34, 36, 38,
              // 40, 42, 44, 46, 48,
              // 50, 52, 54, 56, 58,
              // 1, 2, 3, 4, 5, 6, 7, 8, 9,
              // 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
              // 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
              // 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
              // 40, 41, 42, 43, 44, 45, 46, 47, 48, 49,
              // 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
            ], // Ex - [1,2,3,4,5,6,10,12,15,20,30]
            // second: [
            //   // 1, 2, 3, 4, 5, 6, 7, 8, 9,
            //   // 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            //   // 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
            //   // 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
            //   // 40, 41, 42, 43, 44, 45, 46, 47, 48, 49,
            //   // 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
            // ], // Ex - [1,2,3,4,5,6,10,12,15,20,30]
            // millisecond: [1],
          },
        },
        yaxis: [
          {
            title: "Current in mAh",
            plot: [
              {
                value: "Gain",
                connectnulldata: true,
                type: "column",
                aggregation: "Sum",
                // name: "Gain-Drain plot",
              },
              {
                value: "Drain",
                connectnulldata: true,
                type: "column",
                aggregation: "Sum",
              },
              {
                value: "Voltage",
                connectnulldata: true,
                type: "line",
                // name: "Gain-Drain plot",
              },
              {
                value: "Temperature",
                connectnulldata: true,
                type: "line",
                // name: "Gain-Drain plot",
              },
            ],
          },
          // {
          //   title: "Voltage in V",
          //   plot: [],
          // },
          // {
          //   title: "Temperature in ℃",
          //   plot: [],
          // },
        ],
      },
      schema: [
        {
          name: "Time",
          type: "date",
          format: "%Y-%m-%d %H:%M",
        },
        {
          name: "Gain",
          type: "number",
        },
        {
          name: "Drain",
          type: "number",
        },
        {
          name: "Voltage",
          type: "number",
        },
        {
          name: "Temperature",
          type: "number",
        },
      ],
    };
  },

  watch: {
    data() {
    //   this.loadingData = true;
    //   console.log('watch schema: ', this.schema);
    //   console.log('watch data: ', this.data);
      const chartTable = new FusionCharts.DataStore().createDataTable(this.data, this.schema);
      this.dataSource.data = chartTable;
    //   this.loadingData = false;
    },
  },

  mounted: function() {
    console.log('mounted schema: ', this.schema);
    console.log('mounted data: ', this.data);
    const chartTable = new FusionCharts.DataStore().createDataTable(this.data, this.schema);
    this.dataSource.data = chartTable;
    // setInterval(() => {
    //   if (this.$refs && this.$refs.chartRef && this.$refs.chartRef.chartObj) {
    //     const chartObj = this.$refs.chartRef.chartObj;
    //     chartObj.feedData([
    //       [
    //         (feedDataTime += 1000),
    //         getRandomInt(1, 20),
    //         getRandomInt(70, 100),
    //         getRandomInt(30, 50)
    //       ]
    //     ]);
    //   }
    // }, 3000);
  },

  computed: {},
};
</script>

<style>
.class-absolute {
  margin: 10px;
  height: 350px;
  width: 100%;
  position: absolute;
}
</style>
