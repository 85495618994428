<template>
  <v-container>
    <v-row class="alert-container">
      <v-alert v-if="showMessage" class="alert" :type="messageType" dark>
        {{ message }}
      </v-alert>
    </v-row>
    <v-row class="upload-file-container" justify="center">
      <v-col cols="6">
        <form action="#">
          <v-card class="card" :disabled="uploading">
            <v-overlay
              v-if="uploading"
              class="d-flex flex-column justify-center align-center class-absolute"
              :value="uploading"
              :opacity="1"
            >
              <v-progress-circular :size="100" :width="10" color="lightgrey" indeterminate></v-progress-circular>
            </v-overlay>
            <h2 class="text-center">{{ customerName }}</h2>
            <v-card-title class="file-card-title">Upload file</v-card-title>
            <v-file-input
              class="file-input"
              chips
              counter
              show-size
              small-chips
              truncate-length="15"
              v-model="file"
              accept=".hex"
            ></v-file-input>
            <v-row justify="center">
              <v-col cols="11">
                <v-textarea
                  label="Session Description"
                  maxlength="200"
                  counter="200"
                  outlined
                  v-model="session"
                ></v-textarea>
              </v-col>
            </v-row>
            <!-- <v-expand-transition> -->
            <v-col>
              <v-card>
                <v-card-text>
                  File Details
                </v-card-text>
                <v-row>
                  <v-col>
                    <!-- <v-text-field
                      label="File type"
                      class="text-field"
                      readonly
                      maxlength="30"
                      dense
                      v-model="fileType"
                    ></v-text-field> -->
                    <v-text-field
                      label="Test-case"
                      class="text-field"
                      readonly
                      maxlength="30"
                      dense
                      v-model="testcaseName"
                    ></v-text-field>
                    <v-text-field
                      label="First Logging Date"
                      class="text-field"
                      readonly
                      dense
                      maxlength="50"
                      v-model="startDate"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Device Id"
                      class="text-field"
                      readonly
                      maxlength="30"
                      dense
                      v-model="deviceId"
                    ></v-text-field>
                    <v-text-field
                      label="Last Logging Date"
                      class="text-field"
                      readonly
                      dense
                      v-model="endDate"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="upload-button" justify="end" dense>
                  <v-btn color="green" :disabled="uploading || !file" @click="uploadFile">Upload logger file </v-btn>
                </v-row>
              </v-card>
            </v-col>
            <!-- </v-expand-transition> -->
          </v-card>
        </form>
      </v-col>
    </v-row>
    <!-- <v-progress-linear>
    </v-progress-linear> -->
  </v-container>
</template>

<script>
// import JSZip from 'JSZip';

export default {
  components: {},

  data: () => ({
    fileType: null,
    file: null,
    deviceId: null,
    cellId: null,
    customerName: null,
    testcaseName: null,
    session: null,
    startDate: null,
    endDate: null,
    message: null,
    messageType: null,
    showMessage: false,
    uploading: false,
  }),

  created() {
    this.fetchUserDetails();
  },

  computed: {
    accessToken() {
      return JSON.parse(localStorage.getItem("user")).accessToken;
    },
    userId() {
      return JSON.parse(localStorage.getItem("user")).id;
    },
  },


  watch: {
    file(newValue) {
      this.showMessage = false;
      this.message = "";
      this.uploading = false;
      if (newValue == "" || newValue == null) {
        this.fileType = this.getFileTypeByCode("");
        this.deviceId = "";
        this.cellId = "";
        this.customerName = "";
        this.startDate = "";
        this.endDate = "";
      } else {
        this.parseFileHeader();
      }
    },
    deviceId() {
      this.fetchDeviceDetailsFromDeviceId();
    },
  },

  methods: {
    getFileTypeByCode(code) {
      switch (code) {
        case "HP":
          return "headphones";
        default:
          "";
      }
    },

    parseFileHeader() {
      let reader = new FileReader();

      reader.readAsText(this.file);

      let res = [];
      reader.onload = function() {
        var endIndex = reader.result.lastIndexOf(";");
        const contents = reader.result.substr(0, endIndex);
        res = contents.split(";");
        this.fileType = this.getFileTypeByCode(res[0]);
        this.deviceId = res[2];
        this.cellId = res[3];
        this.startDate =
          res[4].substr(0, 4) +
          "-" +
          res[4].substr(4, 2) +
          "-" +
          res[4].substr(6, 2) +
          " " +
          res[4].substr(8, 2) +
          ":" +
          res[4].substr(10, 2) +
          ":" +
          res[4].substr(12, 2);
        this.endDate =
          res[7].substr(0, 4) +
          "-" +
          res[7].substr(4, 2) +
          "-" +
          res[7].substr(6, 2) +
          " " +
          res[7].substr(8, 2) +
          ":" +
          res[7].substr(10, 2) +
          ":" +
          res[7].substr(12, 2);
      }.bind(this);
    },

    async fetchUserDetails() {
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      reqEndpoint = this.$globals.API_URL + this.$globals.RES_USER + `/${this.userId}`;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.loading = false;
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }

      const data = await response.json();
      this.customerName = data.customer_name;
    },

    async fetchDeviceDetailsFromDeviceId() {
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      reqEndpoint = this.$globals.API_URL + this.$globals.RES_DEVICE + `/${this.deviceId}`;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.loading = false;
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }

      this.testcaseName = null;
      const data = await response.json();
      const testcase = await this.fetchTestcaseById(data.testcase_id);
      if( testcase != null ) {
        this.testcaseName = testcase.name;
      }
      this.customerName = data.brand;
    },

    async fetchTestcaseById(id) {
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let reqEndpoint = "";
      reqEndpoint = this.$globals.API_URL + this.$globals.RES_TESTCASE + `/${id}`;
      const response = await fetch(reqEndpoint, { headers: h });
      if (response.status == 401) {
        this.$store.dispatch("auth/logout").then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.loading = false;
            this.message = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }

      const data = await response.json();
      return data;
    },

    uploadFile() {
      var formdata = new FormData();
      formdata.append("file", this.file);
      formdata.append("sessionDescription", this.session);

      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);

      var requestOptions = {
        method: "POST",
        headers: h,
        body: formdata,
        redirect: "follow",
      };

      this.uploading = true;
      fetch(
        this.$globals.API_URL +
          // `/device-logger-events?deviceId=${this.testCaseId}&sessionDescription=${this.session}&startDate=${this.startDate}&endDate=${this.endDate}`,
          `/device-logger-events?startDate=${this.startDate}&endDate=${this.endDate}`,
        requestOptions
      )
        .then(async (response) => {
          if (response.status == 401) {
            this.$store.dispatch("auth/logout").then(
              () => {
                this.$router.push("/login");
              },
              (error) => {
                this.loading = false;
                this.message = (error.response && error.response.data) || error.message || error.toString();
              }
            );
          }
          this.showMessage = true;
          const data = await response.json();
          this.message = data.message;
          this.uploading = false;
          if (data.success) {
            this.messageType = "success";
          } else {
            this.messageType = "error";
          }
          window.setTimeout(() => {
            this.showMessage = false;
            if (data.success) {
              this.$store.commit("setStoreDeviceType", this.fileType.toLowerCase());
              this.$store.commit("setStoreBrandName", this.customerName);
              this.$store.commit("setStoreSession", this.session);
              this.$store.commit("setStoreDeviceId", this.deviceId);
              this.$store.commit("setStoreStartDate", this.startDate.substr(0, 10));
              this.$store.commit("setStoreEndDate", this.endDate.substr(0, 10));
              this.$router.push("/activity");
            }
          }, 5000);
        })
        .catch((error) => console.log("error", error));
    },
    getUploadProgress() {
      const h = new Headers();
      h.append("Authorization", "Bearer " + this.accessToken);
      let options = {
        method: "GET",
        headers: h,
        redirect: "follow",
      };

      fetch(this.$globals.API_URL + "/upload_progress", options)
        .then(async (response) => {
          if (response.status == 401) {
            this.$store.dispatch("auth/logout").then(
              () => {
                this.$router.push("/login");
              },
              (error) => {
                this.loading = false;
                this.message = (error.response && error.response.data) || error.message || error.toString();
              }
            );
          }
          const data = await response.json();
          // console.log(`data: ${JSON.stringify(data)}`);
          for (let i = 0; i < data.length; i += 1) {
            this.deviceTypes.push(data[i]);
          }
        })
        .catch((e) => {
          console.log(`Error : ${e}`);
          this.$router.push("login");
        });
    },
  },
};
</script>

<style>
.alert-container {
  width: 100%;
  position: absolute;
  z-index: 1;
}
.alert {
  width: 100%;
}
.upload-file-container {
  padding-top: 30px;
  position: relative;
  z-index: 0;
}
.file-input {
  padding: 0.8em;
}
.file-card-title {
  font-size: 14px;
}
.text-field {
  margin: 0.8em;
  padding-left: 2vmin;
}
.upload-button {
  padding: 0.8em;
}
.class-absolute {
  height: 100%;
  width: 100%;
  position: absolute;
}
</style>
