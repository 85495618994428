<template>
  <fusioncharts
    :type="type"
    :width="width"
    :height="height"
    :dataFormat="dataFormat"
    :dataEmptyMessage="dataEmptyMessage"
    :dataSource="dataSource"
    ref="chartRef"
  ></fusioncharts>
</template>

<script>
export default {
  name: "AngularGauge",
  props: {
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    title: {
      type: String,
    },
    data: {
      type: Array,
    },
  },
  data: function() {
    return {
      type: "angulargauge",
      dataFormat: "json",
      loadMessage: 'loading...',
      loadMessageColor: '#000000',
      dataEmptyMessage: 'No data to display',
      dataSource: {
  chart: {
    caption: "Gain Goal",
    subcaption: "All Powerfoyle devices",
    lowerlimit: "0",
    upperlimit: "100",
    showvalue: "1",
    numbersuffix: "%",
    theme: "fusion"
  },
  colorrange: {
    color: [
      {
        minvalue: "0",
        maxvalue: "50",
        code: "#ff2703"
      },
      {
        minvalue: "50",
        maxvalue: "75",
        code: "#FFC533"
      },
      {
        minvalue: "75",
        maxvalue: "100",
        code: "#337700"
      }
    ]
  },
  dials: {
    dial: [
      {
        value: "71",
        tooltext: "<b>9%</b> lesser that target"
      }
    ]
  },
  trendpoints: {
    point: [
      {
        startvalue: "80",
        displayvalue: "Target",
        thickness: "2",
        color: "#E15A26",
        usemarker: "1",
        markerbordercolor: "#E15A26",
        markertooltext: "80%"
      }
    ]
  }
        // chart: {
        //   caption: this.title,
        //   bgColor: "#eeeeee",
        //   subCaption: "",
        //   xAxisName: "",
        //   yAxisName: "",
        //   showValues: "0",
        //   theme: "fusion",
        //   showLegend: false,
        // },
        // data: [],
      },
    };
  },
};
</script>

<style>
</style>
