<template>
  <fusioncharts
    :type="type"
    :width="width"
    :height="height"
    :dataFormat="dataFormat"
    data-empty-message="Data is loading"
    :dataSource="dataSource"
    ref="chartRef"
  ></fusioncharts>
</template>

<script>
export default {
  name: "OverlappedColumn2d",
  props: {
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    caption: {
      type: String,
    },
    categories: {
      type: Array,
    },
    dataset: {
      type: Array,
    },
  },
  data: function() {
    return {
      type: "overlappedcolumn2d",
      dataFormat: "json",
      dataSource: {
        chart: {
    caption: "Sales Targets vs Achieved",
    subcaption: "Bilbus",
    yaxisname: "Revenue (In USD)",
    numberprefix: "$",
    drawcrossline: "1",
    theme: "fusion",
    showvalues: "0"
  },
  categories: [
    {
      category: [
        {
          label: "Oliver"
        },
        {
          label: "Andy"
        },
        {
          label: "Peter"
        },
        {
          label: "Natasha"
        },
        {
          label: "Robert"
        },
        {
          label: "Bruce"
        },
        {
          label: "Wanda"
        }
      ]
    }
  ],
  dataset: [
    {
      seriesname: "Target",
      data: [
        {
          value: "250000"
        },
        {
          value: "200000"
        },
        {
          value: "300000"
        },
        {
          value: "200000"
        },
        {
          value: "270000"
        },
        {
          value: "350000"
        },
        {
          value: "200000"
        }
      ]
    },
    {
      seriesname: "Achieved",
      data: [
        {
          value: "260000"
        },
        {
          value: "180000"
        },
        {
          value: "290000"
        },
        {
          value: "195000"
        },
        {
          value: "300000"
        },
        {
          value: "380000"
        },
        {
          value: "210000"
        }
      ]
    }
  ]
        // chart: {
        //   caption: this.caption,
        //   subCaption: "",
        //   xAxisName: "",
        //   yAxisName: "",
        //   showValues: "0",
        //   theme: "fusion",
        //   showLegend: false,
        // },
        // categories: this.categories,
        // dataset: this.dataset,
      },
    };
  },

  mounted:
    function() {
      
    },

};
</script>

<style>
</style>
