import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import Register from '../views/Register.vue';
import SignUp from '../views/SignUp.vue';
import Login from '../views/Login.vue';
import Profile from '../views/Profile.vue';
// import Configuration from '../views/Configuration.vue'
import Customer from '../views/Customer.vue';
import Activity from '../views/Activity.vue'
import LiveActivity from '../views/LiveActivity.vue'
import Device from '../views/Device.vue'
import RegisterLoraDevice from '../views/RegisterLoraDevice.vue'
// import ActivityMultipleHeadphones from '../views/ActivityNew.vue'
import LoggerFileUpload from '../views/LoggerFileUpload.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  // {
  //   path: '/register',
  //   name: 'Register',
  //   component: Register,
  // },
  {
    path: '/register',
    name: 'Register',
    component: SignUp,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
  },
  // {
  //   path: '/configuration',
  //   name: 'Configuration',
  //   component: Configuration,
  // },
  {
    path: '/customer',
    name: 'Customer',
    component: Customer,
  },
  {
    path: '/device',
    name: 'Device',
    component: Device,
  },
  {
    path: '/register-lora-device',
    name: 'RegisterLoraDevice',
    component: RegisterLoraDevice,
  },
  {
    path: '/live-activity',
    name: 'LiveActivity',
    component: LiveActivity,
  },
  {
    path: '/activity',
    name: 'Activity',
    component: Activity,
  },
  // {
  //   path: '/activity-multiple-headphones',
  //   name: 'ActivityMultipleHeadphones',
  //   component: ActivityMultipleHeadphones,
  // },
  {
    path: '/logger-file-upload',
    name: 'LoggerFileUpload',
    component: LoggerFileUpload,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && (!loggedIn || loggedIn == 'undefined') ) {
    return next('/login');
  }
  return next();
});

export default router
