const environment = 'prototype';
//const environment = 'product';
export default {
  // API_URL: 'http://localhost:8080/v1',
  // API_URL: 'https://devapi.swedencentral.cloudapp.azure.com/v1',
  // Old test environment
  // API_URL: 'https://api.exeger.com/v1',
  // Prometheus
  API_URL: 'https://proto-api.exeger.com/v1',

  // Resources
  RES_CUSTOMER_BRAND: '/customer-brand',
  RES_USER: '/user',
  RES_DEVICE: '/device',
  RES_DEVICE_EVENTS: '/device-events',
  RES_TESTCASE: '/testcase',

  user_roles: {
    ADMIN: "ROLE_ADMIN",
    POWER_USER: "ROLE_POWER_USER",
    USER: "ROLE_USER",
  },
  
  CUSTOMER_FIELD_LABEL: (environment == 'prototype' ? 'Customer' : 'Brand name'),
}
