<template>
  <v-app id="smarthome">
    <v-navigation-drawer v-if="currentUser" v-model="drawer" app clipped color="#eee">
      <v-list class="list-item" dense>
        <v-list-item to="/">
          <v-list-item-action>
            <v-icon>mdi-home-analytics</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Dashboard
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item v-if="isAdmin" to="/live-activity">
          <v-list-item-action>
            <v-icon>mdi-chart-line</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Live Activity
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item to="/activity">
          <v-list-item-action>
            <v-icon>mdi-chart-bar</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Activity
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item to="/activity-multiple-headphones">
          <v-list-item-action>
            <v-icon>mdi-poll</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Activity Multiple Headphones
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item v-if="isAdmin || isPowerUser" to="/logger-file-upload">
          <v-list-item-action>
            <v-icon>mdi-file-upload-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Logger File Upload
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item v-if="isAdmin" to="/configuration">
          <v-list-item-action>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Configuration
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item v-if="isAdmin" to="/customer">
          <v-list-item-action>
            <v-icon>mdi-briefcase-account</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Customers
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="isAdmin" to="/device">
          <v-list-item-action>
            <v-icon>mdi-solar-panel</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Testcases / Devices
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item v-if="isAdmin" to="/register-lora-device">
          <v-list-item-action>
            <v-icon>mdi-wifi-plus</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Register LoRa Device
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left color="#eee">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Exeger Portal</v-toolbar-title>
      <v-spacer></v-spacer>
      <div v-if="!currentUser" class="ml-auto">
        <router-link to="/register" class="nav-link">
          <v-icon class="navbar-nav" color="success">mdi-account-plus</v-icon>
        </router-link>
        <router-link to="/login" class="nav-link">
          <v-icon class="navbar-nav" color="primary">mdi-login</v-icon>
        </router-link>
      </div>
      <div v-else class="ml-auto">
        <v-menu v-model="menu" :close-on-content-click="false" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" size="30" v-bind="attrs" v-on="on">
              {{accountIcon}}
            </v-icon>
          </template>
          <v-card class="d-flex flex-column">
            <v-row>
              <v-col>
                <v-avatar color="blue" size="80">
                  <v-img :src="avatarPicture"> </v-img>
                </v-avatar>
              </v-col>
              <v-row>
                <v-col class="username">
                  <b>{{ currentUser.name }} </b>
                  <br />
                  {{ currentUser.email }}
                </v-col>
              </v-row>
            </v-row>
            <v-row class="d-flex flex-row-reverse">
              <v-btn
                class="btn-sign-out"
                small
                tile
                @click="logout">
                <v-icon left size="17">
                  mdi-logout
                </v-icon>
                Sign out
              </v-btn>
            </v-row>
          </v-card>
        </v-menu>
      </div>
    </v-app-bar>

    <v-main class="main-app">
      <router-view />
    </v-main>

    <v-footer app>
      <span>Exeger &copy; {{ new Date().getFullYear() }}</span>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "exegerPortal",
  data: () => ({
    drawer: null,
    menu: false,
  }),
  created() {
    // console.log(`this.currentUser: ${JSON.stringify(this.currentUser)}`);
    this.$vuetify.theme.dark = false;
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    isAdmin() {
      return this.$store.state.auth.user.roles.includes('ROLE_ADMIN');
    },
    isPowerUser() {
      return this.$store.state.auth.user.roles.includes('ROLE_POWER_USER');
    },
    isUser() {
      return this.$store.state.auth.user.roles.includes('ROLE_USER');
    },
    avatarPicture() {
      if (this.currentUser.picture != null) {
        return this.currentUser.picture;
      } else {
        return "./icon/avatar_2x.png";
      }
    },
    accountIcon() {
      return this.$store.state.auth.user.roles.includes('ROLE_ADMIN')
      ? 'mdi-account-tie-hat'
      : (this.$store.state.auth.user.roles.includes('ROLE_POWER_USER')
        ? 'mdi-account-tie'
        : 'mdi-account'
        );
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout").then(
        () => {
          console.log("logout");
          this.$router.push("/login");
        },
        (error) => {
          this.loading = false;
          this.message = (error.response && error.response.data) || error.message || error.toString();
        }
      );
    },
  },
};
</script>

<style scoped>
.main-app {
  background-color: #f7f7f7;
}
.nav-link {
  text-decoration: none;
  color: inherit;
}
.list-item :hover {
  color: dodgerblue;
  background: lightcyan;
}
.toolbar-title-background {
  background-image: url();
}
.username {
  padding-top: 25px;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 30px;
}
.btn-sign-out {
  margin-top: 20px;
  background-color: lightgrey;
  font-weight: 400;
  font-size: 10px;
}
</style>
